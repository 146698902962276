<template>
  <div class="filter-container">
    <div
      class="filter-group"
      :class="customClasses"
    >
      <div
        v-for="(filterOption, idx) in primaryFilters"
        :key="idx"
        class="filter-group-item"
        :class="{'filter-group-item-active': selectedPrimaryFilter === idx}"
        @click="onFilterSelected(idx)"
      >
        {{ filterOption }}
      </div>
    </div>
    <vue-multi-select
      v-if="hasSecondaryFilters"
      class="table-cell-dropdown"
      style="width: 200px !important; z-index: 201;"
      :model-value="selectedSecondaryFilter"
      :options="secondaryFilterOptions"
      :allow-empty="false"
      :show-labels="false"
      @update:model-value="updateSecondaryFilter"
    >
      <template
        v-if="hasGroupText"
        #singleLabel="{ option }"
      >
        <strong>{{ secondaryFilters[selectedPrimaryFilter].groupText }}:</strong> {{ option }}
      </template>
    </vue-multi-select>
  </div>
</template>
<script>
import VueMultiSelect from 'vue-multiselect';
import filtersConverter from '@/store/helpers/filtersConverter';

export default {
  components: {
    VueMultiSelect,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    modelValue: {
      // Index of current filter
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      /**
       * Primary filters example:
       * {
       *  '0': 'Overview',
       *  '1': 'Performance',
       * }
       */
      primaryFilters: {},

      /**
       * Secondary filters example:
       * {
       *  '1': {
       *    '1': 'YTD',
       *    '2': 'LTD',
       *    '3': 'LTM',
       *    'groupText': 'Time period',
       *  }
       * }
       */
      secondaryFilters: {},

      selectedPrimaryFilter: '0', // Number as string
      selectedSecondaryFilter: '', // String
    };
  },
  computed: {
    hasSecondaryFilters() {
      return this.selectedPrimaryFilter in this.secondaryFilters;
    },
    hasGroupText() {
      return 'groupText' in this.secondaryFilters[this.selectedPrimaryFilter];
    },
    secondaryFilterOptions() {
      const temporaryFilters = { ...this.secondaryFilters[this.selectedPrimaryFilter] };
      delete temporaryFilters.groupText;
      return Object.values(temporaryFilters);
    },
  },
  watch: {
    modelValue(newVal) {
      const stringNewVal = newVal.toString();
      if (stringNewVal in this.primaryFilters) {
        this.selectedPrimaryFilter = stringNewVal;
        if (stringNewVal in this.secondaryFilters) {
          // Set secondary filter to first value.
          this.selectedSecondaryFilter = this.secondaryFilters[stringNewVal][stringNewVal];
        }
      } else {
        // Find corresponding secondary filter and select the primary and secondary filter.
        Object.keys(this.secondaryFilters).forEach((idx) => {
          if (stringNewVal in this.secondaryFilters[idx]) {
            this.selectedPrimaryFilter = idx;
            this.selectedSecondaryFilter = this.secondaryFilters[idx][stringNewVal];
          }
        });
      }
    },
  },
  mounted() {
    [this.primaryFilters, this.secondaryFilters] = filtersConverter(this.filterOptions);
  },
  methods: {
    onFilterSelected(idx) {
      this.$log.info('onFilterSelected, newIdx:', idx);
      this.$emit('update:modelValue', Number(idx));
    },
    updateSecondaryFilter(filter) {
      const selectedSecondaryFilterGroup = this.secondaryFilters[this.selectedPrimaryFilter];
      const idx = Object.keys(selectedSecondaryFilterGroup).find((key) => selectedSecondaryFilterGroup[key] === filter);
      this.$log.info('Update secondary filter idx:', idx);
      this.$emit('update:modelValue', Number(idx));
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
