<template>
  <div :class="{...customClasses, 'date-input': true, 'input-no-value': !isValueSet}">
    <template v-if="dateFormatStr === 'DD/MM/YYYY'">
      <input
        class="date-input-dd"
        type="text"
        :value="dd"
        minlength="2"
        maxlength="2"
        max="31"
        placeholder="DD"
        @input="updateDD($event.target.value)"
        @blur="onBlur"
      >
      <span class="date-input-separator">/</span>
      <input
        class="date-input-mm"
        type="text"
        :value="mm"
        minlength="2"
        maxlength="2"
        max="12"
        placeholder="MM"
        @input="updateMM($event.target.value)"
        @blur="onBlur"
      >
    </template>
    <template v-else>
      <input
        class="date-input-mm"
        type="text"
        :value="mm"
        minlength="2"
        maxlength="2"
        max="12"
        placeholder="MM"
        @input="updateMM($event.target.value)"
        @blur="onBlur"
      >
      <span class="date-input-separator">/</span>
      <input
        class="date-input-dd"
        type="text"
        :value="dd"
        minlength="2"
        maxlength="2"
        max="31"
        placeholder="DD"
        @input="updateDD($event.target.value)"
        @blur="onBlur"
      >
    </template>

    <span class="date-input-separator">/</span>
    <input
      class="date-input-yyyy"
      type="text"
      :value="yyyy"
      minlength="4"
      maxlength="4"
      placeholder="YYYY"
      @input="updateYYYY($event.target.value)"
      @blur="onBlur"
    >
  </div>
</template>
<script>
export default {
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    dd: {
      type: String,
      default: '',
    },
    mm: {
      type: String,
      default: '',
    },
    yyyy: {
      type: String,
      default: '',
    },
    isValueSet: {
      type: Boolean,
      required: true,
    },
    dateFormatStr: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  emits: ['updateDD', 'updateMM', 'updateYYYY', 'blur'],
  methods: {
    updateDD(dd) {
      this.$emit('updateDD', dd);
    },
    updateMM(mm) {
      this.$emit('updateMM', mm);
    },
    updateYYYY(yyyy) {
      this.$emit('updateYYYY', yyyy);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
  },
};
</script>
