<template>
  <div class="verify-document">
    <nav class="verify-document-nav">
      <verify-nav
        v-if="document"
        :custom-classes="{ 'verify-document-nav-row-1': true }"
        :auto-save-status="autoSaveStatus"
        :confirm-document-status="confirmDocumentStatus"
        :can-redo="canRedo"
        :is-repeating="tableForCurrentBreadcrumb.isRepeating"
        :file-name="document.fileName"
        :extraction-progress-stage-name="document.extractionProgressStageName"
        :document-type="document.documentTypeDisplay"
        :can-verify-row="canVerifyRow"
        :can-verify-column="canVerifyColumn"
        @close="onClosePressed"
        @verify="onConfirmTriggered"
        @verifyMenuActionTriggered="onVerifyMenuActionTriggered"
        @updateDocumentType="onUpdateDocumentType"
      />
      <breadcrumb-nav
        :custom-classes="{'verify-document-nav-row-2': true}"
        :current-breadcrumb-index="breadcrumbIndex"
        :breadcrumbs="breadcrumbs"
        :document-request-id="documentRequestId"
        :document-is-popped-out="!showViewerPane"
        @popOutToggled="onPopoutToggled"
      />
    </nav>
    <div class="content-container">
      <splitpanes>
        <pane>
          <keep-alive>
            <metric-section
              v-if="tableForCurrentBreadcrumb.table !== undefined"
              :key="tableForCurrentBreadcrumb.tableIndex"
              :document-request-id="documentRequestId"
              :initial-table="tableForCurrentBreadcrumb.table"
              :table-idx="tableForCurrentBreadcrumb.tableIndex"
              :breadcrumb-idx="breadcrumbIndex"
              :is-table-repeating="tableForCurrentBreadcrumb.isRepeating"
              :verify-menu-action-triggers="verifyMenuActionTriggers"
              :has-calculated-totals="tableForCurrentBreadcrumb.hasCalculatedTotals"
              :default-transposed="defaultTranspose"
            />
          </keep-alive>
        </pane>
        <pane v-if="showViewerPane">
          <pspdfkit-wrapper-viewer
            :document-request-id="documentRequestId"
            :local-document-path="localDocumentPath"
          />
        </pane>
      </splitpanes>
    </div>
  </div>
  <update-document-type-modal
    :show="showUpdateDocumentTypeModal"
    :document-type="selectedDocumentType"
    :update-document-type-is-loading="updateDocumentTypeIsLoading"
    @close="closeUpdateDocumentTypeModal"
    @confirmUpdate="confirmUpdateDocumentType"
  />
</template>
<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import defaultVerifyMenuActions from '@/store/helpers/mapping/defaultVerifyMenuActions';
import { extractionProgressMappingToBackend } from '@/store/helpers/mapping/extractionProgresses';
import PspdfkitWrapperViewer from '@/components/pdfReader/PspdfkitWrapperViewer.vue';
import VerifyNav from '@/components/verify/VerifyNav.vue';
import BreadcrumbNav from '@/components/verify/BreadcrumbNav.vue';
import MetricSection from '@/components/verify/MetricSection.vue';
import isSet from '@/store/helpers/isSet';
import { allowedStates } from '@/store/helpers/storeState';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';
import UpdateDocumentTypeModal from '@/components/modals/UpdateDocumentTypeModal.vue';
import { errorMessages } from '@/store/helpers/display/toastMessages';

export default {
  components: {
    MetricSection,
    BreadcrumbNav,
    VerifyNav,
    Pane,
    PspdfkitWrapperViewer,
    Splitpanes,
    UpdateDocumentTypeModal,
  },
  beforeRouteLeave(to, from, next) {
    // If there are changes to save and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmRemainUnsaved()) {
      next(false);
    } else {
      next();
    }
  },
  props: {
    // These props are sent from the url. Because breadcrumbIndex is a Number,
    // it is validated in index.js - this unfortunately means that any props
    // added or removed here, also need to be updated in index.js to be passed in here.
    documentRequestId: {
      type: String,
      required: true,
    },
    breadcrumbIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      attemptingConfirmation: false,
      localDocumentPath: `${window.location.origin}/local_documents/performance.pdf`,
      toast: useToast(),
      verifyMenuActionTriggers: defaultVerifyMenuActions(),
      showViewerPane: true,
      showUpdateDocumentTypeModal: false,
      selectedDocumentType: {},
      updateDocumentTypeIsLoading: false,
      defaultTabTitle: window.document.title,
    };
  },
  computed: {
    ...mapGetters({
      autoSaveStatus: 'verifyDocument/autoSaveStatus',
      confirmDocumentStatus: 'verifyDocument/confirmDocumentStatus',
      canRedo: 'verifyDocument/canRedo',
      canVerifyRow: 'verifyDocument/canVerifyRow',
      canVerifyColumn: 'verifyDocument/canVerifyColumn',
      document: 'verifyDocument/document',
    }),
    grouping() {
      return this.document ? this.document.grouping : [];
    },
    tables() {
      return this.document ? this.document.tables : {};
    },
    breadcrumbs() {
      if (!Object.keys(this.grouping).length || !this.grouping.groups.length) {
        return [];
      }

      return this.grouping.groups.map((g) => ({
        navText: g.groupDisplay,
      }));
    },
    groupForCurrentBreadcrumb() {
      if (!this.grouping?.groups) {
        return null;
      }
      return this.grouping.groups[this.breadcrumbIndex];
    },
    defaultTranspose() {
      return [
        'am:performance:fund',
        'am:performance:income_statement',
        'am:performance:cash_flow',
      ].includes(this.groupForCurrentBreadcrumb.groupIdentifier);
    },
    tableForCurrentBreadcrumb() {
      const failedToLoadTable = { tableIndex: -1, table: undefined };
      if (!this.groupForCurrentBreadcrumb) {
        return failedToLoadTable;
      }
      const { tableIndex } = this.groupForCurrentBreadcrumb;
      if (!isSet(tableIndex)) {
        this.$log.error('Each breadcrumb should only have one table. Given:', tableIndex);
        return failedToLoadTable;
      }
      const table = this.tables[tableIndex];
      const isRepeating = isSet(this.groupForCurrentBreadcrumb.groupIdentifier);
      const hasCalculatedTotals = isRepeating ? this.groupForCurrentBreadcrumb.calculatedTotals : false;

      return {
        tableIndex, table, isRepeating, hasCalculatedTotals,
      };
    },
  },
  watch: {
    document(loadedDocument) {
      if (loadedDocument) {
        window.document.title = `${this.defaultTabTitle} - ${loadedDocument.fileName}`;
      }
    },
    autoSaveStatus(newStatus) {
      // A good vuex candidate:
      this.$log.info('autoSaveStatus watch:', newStatus, 'attempting confirmation:', this.attemptingConfirmation);

      if (this.attemptingConfirmation) {
        if (newStatus === allowedStates.IS_READY) {
          this.confirmDocument();
          this.attemptingConfirmation = false;
          return;
        }
        if (newStatus === allowedStates.IS_ERRORING) {
          this.attemptingConfirmation = false;
          this.$log.error('Document confirmation stopped due to auto-save error');
        }
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.document.title = this.defaultTabTitle;
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    this.newDocReqInit();
  },
  methods: {
    ...mapActions({
      documentRequestInit: 'verifyDocument/init',
      getSettings: 'localisation/lazyInit',
      initSettingsWithDefault: 'localisation/initWithDefault',
      postDocumentRequestIsVerifiedStatus: 'verifyDocument/postDocumentRequestIsVerifiedStatus',
      postDocumentRequestState: 'verifyDocument/postDocumentRequestState',
      requeueDocuments: 'documents/requeueDocuments',
    }),
    confirmLeave() {
      // eslint-disable-next-line no-alert
      return window.confirm('You have changes that have not been saved. Click Cancel to go back or OK to save and close');
    },
    confirmRemainUnsaved() {
      return [allowedStates.IS_PENDING, allowedStates.IS_LOADING].includes(this.autoSaveStatus)
        && !this.confirmLeave();
    },
    beforeWindowUnload(e) {
      if (this.confirmRemainUnsaved()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    newDocReqInit() {
      this.$log.info('newDocReqInit. Resetting and fetching new data...');
      this.resetData();

      const getSettingsProm = this.getSettings()
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching user settings, initialising with defaults');
          this.initSettingsWithDefault();
        });

      const documentRequestInit = this.documentRequestInit({ documentRequestId: this.documentRequestId })
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching metrics for this document');
        });

      Promise.all([getSettingsProm, documentRequestInit])
        .then(() => {
          this.$log.info('Successfully initialised data for VerifyDocument');
          this.$log.info('Loaded groups:', this.grouping);
        });
    },
    resetData() {
      this.verifyMenuActionTriggers = defaultVerifyMenuActions();
    },
    async onConfirmTriggered(e) {
      if (e.shiftKey) {
        this.toast.info('Confirm triggered with shift key held');
        await this.postDocumentRequestState({
          documentRequestId: this.documentRequestId, isVerified: true, stage: extractionProgressMappingToBackend.EXT_PREPARED,
        }).then(() => {
          this.toast.success('Document successfully prepared');
        });
        return;
      }
      this.$log.info('Confirm triggered');
      this.attemptingConfirmation = true;
      this.onVerifyMenuActionTriggered(verifyMenuActions.FULL_SAVE);
    },
    confirmDocument() {
      return this.postDocumentRequestIsVerifiedStatus({
        documentRequestId: this.documentRequestId,
        isVerified: true,
      })
        .then(() => {
          this.toast.success('Document successfully confirmed as verified');
          this.onClosePressed();
        })
        .catch((e) => {
          const msg = 'Something went wrong while setting the verification status of this pdf to \'Verified\'';
          this.toast.error(msg);
          throw (e);
        });
    },
    onVerifyMenuActionTriggered(action) {
      this.verifyMenuActionTriggers[action] += 1;
    },
    onClosePressed() {
      this.$log.info('Closing document');
      let routeName = 'Verification';

      // Check if there is a valid previous route to go back to.
      if (this.$route.query.previousView) {
        const allRoutes = [];
        this.$router.options.routes.forEach((route) => {
          allRoutes.push(route.name);
        });
        if (allRoutes.includes(this.$route.query.previousView)) {
          this.$log.info('Previous view found in query:', this.$route.query.previousView);
          routeName = this.$route.query.previousView;
        } else {
          this.$log.warn('Previous view in query does not exist:', this.$route.query.previousView);
        }
      }

      return this.$router.push({ name: routeName, query: { type: this.$route.query.type } });
    },
    onPopoutToggled() {
      if (this.showViewerPane) {
        const routeData = this.$router.resolve({ path: `/verification/${this.documentRequestId}/document` });
        window.open(routeData.href, '_blank', 'toolbar=0,location=0,menubar=0');
      }
      this.showViewerPane = !this.showViewerPane;
    },
    closeUpdateDocumentTypeModal() {
      this.showUpdateDocumentTypeModal = false;
    },
    onUpdateDocumentType(documentType) {
      this.selectedDocumentType = documentType;
      this.updateDocumentTypeIsLoading = false;
      this.showUpdateDocumentTypeModal = true;
    },
    confirmUpdateDocumentType() {
      this.updateDocumentTypeIsLoading = true;
      this.$log.info('Confirm update document type:', this.selectedDocumentType);
      const documents = [
        {
          document_request_id: this.documentRequestId,
          document_type: this.selectedDocumentType.key,
        },
      ];
      this.requeueDocuments(documents)
        .then(() => {
          this.onClosePressed();
        })
        .catch((e) => {
          this.toast.error(errorMessages.REQUEUE_DOCUMENT);
          this.$log.error('Requeue document failed', e);
        });
    },
  },
};
</script>
