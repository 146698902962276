<template>
  <div
    class="top-nav-groups top-nav-spaced-groups"
    :class="customClasses"
  >
    <div class="left-group">
      <floating-dropdown
        :is-enabled="true"
        :custom-classes="{'dropdown-position-below': true}"
        :custom-dropdown-style="{'min-width': 'fit-content', padding: '8px 0px'}"
      >
        <template #trigger>
          <div class="top-nav-item top-nav-item-menu">
            View
          </div>
        </template>
        <template #content>
          <div
            class="dropdown-compound-item"
            @click="onTransposeTable"
          >
            Transpose table
          </div>
          <div
            class="dropdown-compound-item confidence-indicator-toggle"
            @click="onToggleConfidenceIndicators"
          >
            <p>Confidence Indicators</p>
            <span
              v-if="showConfidenceIndicators"
              class="confidence-toggle-icon"
            >
              <eye-icon size="0.5x" />
            </span>
            <span
              v-else
              class="confidence-toggle-icon"
            >
              <eye-off-icon size="0.5x" />
            </span>
          </div>
        </template>
      </floating-dropdown>
      <floating-dropdown
        :is-enabled="true"
        :custom-classes="{'dropdown-position-below': true}"
        custom-dropdown-style="min-width: 240px;"
      >
        <template #trigger>
          <div class="top-nav-item top-nav-item-menu">
            Edit
          </div>
        </template>
        <template #content>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onAddNewRowAbove"
          >
            Create group at the start
          </div>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onAddNewRowBelow"
          >
            Create group at the end
          </div>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onDeleteSelectedRow"
          >
            Delete selected group
          </div>
          <hr>
          <div
            class="dropdown-compound-item"
            @click="onUpdateTableCurrency"
          >
            Change currency
          </div>
          <div
            class="dropdown-compound-item"
            @click="onIncreaseTableScale"
          >
            Increase Currency Scale (000)
          </div>
          <div
            class="dropdown-compound-item"
            @click="onDecreaseTableScale"
          >
            Decrease Currency Scale (000)
          </div>
          <hr>
          <div
            class="dropdown-compound-item"
            @click="onVerifyTable"
          >
            Verify Table
          </div>
          <div
            :class="canVerifyColumn ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onVerifyColumn"
          >
            Verify Column
          </div>
          <div
            :class="canVerifyRow ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onVerifyRow"
          >
            Verify Row
          </div>
        </template>
      </floating-dropdown>
      <vue-multi-select
        class="table-cell-dropdown"
        style="width: 15vw !important"
        :model-value="selectedDocumentType"
        :options="Object.values(documentTypesToDisplayMapping)"
        :allow-empty="false"
        @update:model-value="updateDocumentType"
      />
    </div>
    <div class="document-name">
      {{ trimmedFileName }}
    </div>
    <div class="right-group">
      <div
        v-tooltip="'Undo (Ctrl + Z)'"
        :class="canUndo ? 'top-nav-icon icon-container': 'top-nav-icon'"
        @click="onUndo"
      >
        <corner-up-left-icon
          size="1.5x"
          :stroke="canUndo ? 'black' : '#5e5c5c'"
        />
      </div>
      <div
        v-tooltip="'Redo (Ctrl + Shift + Z)'"
        :class="canRedo ? 'top-nav-icon icon-container': 'top-nav-icon'"
        @click="onRedo"
      >
        <corner-up-right-icon
          size="1.5x"
          :stroke="canRedo ? 'black' : '#5e5c5c'"
        />
      </div>
      <div
        v-tooltip="'Save changes'"
        class="top-nav-item icon-container"
        @click="save"
      >
        <refresh-cw-icon
          size="1.5x"
          :class="{'icon-rotate': autoSaveStatusIsLoading}"
          :stroke="autoSaveStatusIsBlank ? '#5e5c5c' : 'black'"
        />
      </div>
      <div v-tooltip="userCanConfirmDocument ? 'Confirm verified metrics' : 'No permission to confirm documents'">
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          message="CONFIRM"
          :custom-classes="{'top-nav-item': true, 'btn-fixed-width-m': true }"
          :style="{ 'font-size': '18px', 'padding': '2px' }"
          :is-disabled="autoSaveStatusIsLoading || confirmDocumentIsLoading || !userCanConfirmDocument"
          :is-loading="confirmDocumentIsLoading"
          @onClick="verify"
        />
      </div>
      <div
        v-tooltip="'Close document'"
        class="top-nav-item icon-container"
        @click="close"
      >
        <x-icon
          size="2.0x"
          class="custom-class"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  RefreshCwIcon, XIcon, CornerUpLeftIcon, CornerUpRightIcon, EyeIcon, EyeOffIcon,
} from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';
import { allowedStates } from '@/store/helpers/storeState';
import FloatingDropdown from '@/components/forms/FloatingDropdown.vue';
import VueMultiSelect from 'vue-multiselect';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    ActionButton,
    FloatingDropdown,
    RefreshCwIcon,
    XIcon,
    CornerUpLeftIcon,
    CornerUpRightIcon,
    EyeIcon,
    EyeOffIcon,
    VueMultiSelect,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    autoSaveStatus: {
      type: String,
      required: true,
    },
    confirmDocumentStatus: {
      type: String,
      required: true,
    },
    canRedo: {
      type: Boolean,
      default: false,
    },
    isRepeating: {
      type: Boolean,
      default: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    extractionProgressStageName: {
      type: String,
      required: true,
    },
    documentType: {
      type: String,
      required: true,
    },
    canVerifyRow: {
      type: Boolean,
      required: true,
    },
    canVerifyColumn: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'close',
    'verify',
    'verifyMenuActionTriggered',
    'updateDocumentType',
  ],
  data() {
    return {
      toast: useToast(),
      selectedDocumentType: '',
      documentTypes: {},
      canConfirmDocuments: false,
      showConfidenceIndicators: true,
    };
  },
  computed: {
    ...mapGetters({
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
    }),
    autoSaveStatusIsLoading() {
      return this.autoSaveStatus === allowedStates.IS_LOADING;
    },
    autoSaveStatusIsBlank() {
      return this.autoSaveStatus === allowedStates.IS_BLANK;
    },
    confirmDocumentIsLoading() {
      return this.confirmDocumentStatus === allowedStates.IS_LOADING;
    },
    confirmDocumentIsBlank() {
      return this.confirmDocumentStatus === allowedStates.IS_BLANK;
    },
    canUndo() {
      return this.autoSaveStatus === allowedStates.IS_PENDING;
    },
    trimmedFileName() {
      return this.fileName.replace(/\.[^/.]+$/, '');
    },
    userCanConfirmDocument() {
      return this.canConfirmDocuments || this.extractionProgressStageName === 'EXT_MANUAL_QA';
    },
  },
  watch: {
    documentType: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal && Object.keys(this.documentTypes).length) {
          this.selectedDocumentType = this.documentTypes[newVal].display;
        }
      },
    },
  },
  async created() {
    this.canConfirmDocuments = await (this.checkPermission('CAN_VERIFY_DOCUMENTS'));
  },
  mounted() {
    this.getDocumentTypes()
      .then((response) => {
        this.documentTypes = response;
        this.selectedDocumentType = response[this.documentType].display;
      })
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching document types');
      });
  },
  methods: {
    ...mapActions({
      getDocumentTypes: 'documentTypes/lazyGet',
      checkPermission: 'localisation/checkPermission',
    }),
    close() {
      this.$emit('close');
    },
    save() {
      if (this.autoSaveStatusIsBlank) {
        this.toast.info('No data to save');
        return;
      }
      if (this.autoSaveStatusIsLoading) {
        this.toast.warning('Saving already in progress');
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.SAVE);
    },
    verify(e) {
      this.$emit('verify', e);
    },
    onVerifyMenuActionTriggered(action) {
      this.$emit('verifyMenuActionTriggered', action);
    },
    onToggleConfidenceIndicators() {
      this.showConfidenceIndicators = !this.showConfidenceIndicators;
      this.$log.info('onToggleConfidenceIndicators');
      this.onVerifyMenuActionTriggered(verifyMenuActions.TOGGLE_CONFIDENCE_INDICATORS);
    },
    onTransposeTable() {
      this.$log.info('onTransposeTable');
      this.onVerifyMenuActionTriggered(verifyMenuActions.TRANSPOSE_TABLE);
    },
    onAddNewRowAbove() {
      this.$log.info('onAddNewRowAbove');
      this.onVerifyMenuActionTriggered(verifyMenuActions.ADD_NEW_ROW_ABOVE);
    },
    onAddNewRowBelow() {
      this.$log.info('onAddNewRowBelow');
      this.onVerifyMenuActionTriggered(verifyMenuActions.ADD_NEW_ROW_BELOW);
    },
    onDeleteSelectedRow() {
      this.$log.info('onDeleteSelectedRow');
      this.onVerifyMenuActionTriggered(verifyMenuActions.DELETE_SELECTED_ROW);
    },
    onUpdateTableCurrency() {
      this.$log.info('onUpdateTableCurrency');
      this.onVerifyMenuActionTriggered(verifyMenuActions.UPDATE_TABLE_CURRENCY);
    },
    onIncreaseTableScale() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.INCREASE_TABLE_SCALE);
    },
    onDecreaseTableScale() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.DECREASE_TABLE_SCALE);
    },
    onVerifyTable() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_TABLE);
    },
    onVerifyColumn() {
      if (this.canVerifyColumn) {
        this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_COLUMN);
      }
    },
    onVerifyRow() {
      if (this.canVerifyRow) {
        this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_ROW);
      }
    },
    onUndo() {
      if (!this.canUndo) {
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.UNDO);
    },
    onRedo() {
      if (!this.canRedo) {
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.REDO);
    },
    updateDocumentType(newValue) {
      const response = {
        key: Object.keys(this.documentTypes).find((key) => this.documentTypes[key].display === newValue),
        display: newValue,
      };
      this.$log.info('Update document type:', response);
      this.$emit('updateDocumentType', response);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
