<template>
  <div
    class="top-nav-groups"
    :class="customClasses"
  >
    <div class="left-group">
      <breadcrumbs
        :current-breadcrumb-index="currentBreadcrumbIndex"
        :breadcrumbs="breadcrumbs"
        :document-request-id="documentRequestId"
      />
    </div>
    <div
      class="right-group"
      style="margin-left: auto"
    >
      <alternative-answers />
      <div
        class="top-nav-item icon-container"
        @click="onPopoutToggled"
      >
        <minimize2-icon
          v-if="documentIsPoppedOut"
          v-tooltip="'Show document in this window'"
          size="1.5x"
          class="custom-class"
        />
        <maximize2-icon
          v-else
          v-tooltip="'Open document in a new window'"
          size="1.5x"
          class="custom-class"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Maximize2Icon, Minimize2Icon } from '@zhuowenli/vue-feather-icons';
import Breadcrumbs from '@/components/verify/Breadcrumbs.vue';
import AlternativeAnswers from '@/components/verify/AlternativeAnswers.vue';

export default {
  components: {
    AlternativeAnswers, Breadcrumbs, Maximize2Icon, Minimize2Icon,
  },
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    currentBreadcrumbIndex: {
      type: Number,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    documentIsPoppedOut: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['popOutToggled'],
  methods: {
    onPopoutToggled() {
      this.$emit('popOutToggled');
    },
  },
};
</script>
