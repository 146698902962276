<template>
  <modal
    :show="show"
    title="Change Currency"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p class="">
        Change the currency of the entire column to:
      </p>
      <select-input
        v-model="selectedCurrency"
        select-id="change-column-currency-selector"
        select-placeholder="-"
        name="change currency for column"
        :options="currencyOptions"
        :should-hide-default-option="false"
      />
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG_BORDERLESS"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          message="CHANGE"
          :custom-classes="{'top-nav-item': true}"
          @onClick="onChangeCurrency"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import { currencyMappingToDisplayOption } from '@/store/helpers/mapping/currencies';
import SelectInput from '@/components/forms/SelectInput.vue';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    SelectInput,
    ActionButton,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'confirmChangeColumnCurrency'],
  data() {
    return {
      selectedCurrency: '',
      currencyOptions: currencyMappingToDisplayOption,
    };
  },
  methods: {
    reset() {
      this.selectedCurrency = '';
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    onChangeCurrency() {
      this.$emit('confirmChangeColumnCurrency', this.selectedCurrency);
      this.reset();
    },
  },
};
</script>
