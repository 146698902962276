<template>
  <div
    v-tooltip="tooltipText"
    :class="{'confidence-bars': true, 'hidden': !show}"
  >
    <div
      class="confidence-bar confidence-bar-0"
      :class="confidenceBarColourClass"
    />
    <div
      class="confidence-bar confidence-bar-1"
      :class="confidenceBarColourClass"
    />
    <div
      class="confidence-bar confidence-bar-2"
      :class="confidenceBarColourClass"
    />
  </div>
</template>
<script>
import { inject } from 'vue';
import { confidenceToColourClass } from '../../store/helpers/mapping/confidenceThresholds';

export default {
  name: 'ConfidenceBars',
  props: {
    confidence: {
      type: Number,
      default: 0.0,
    },
  },
  setup() {
    // If injected key is not provided in the parent chain,
    // show confidence indicators by default
    const show = inject('showConfidenceIndicators', true);
    return { show };
  },
  computed: {
    confidenceBarColourClass() {
      return confidenceToColourClass(this.confidence);
    },
    tooltipText() {
      if (this.confidenceBarColourClass === 'confidence-low') {
        return 'Low confidence';
      }
      if (this.confidenceBarColourClass === 'confidence-medium') {
        return 'Medium confidence';
      }
      if (this.confidenceBarColourClass === 'confidence-high') {
        return 'High confidence';
      }
      return 'No value';
    },
  },
};
</script>
