<template>
  <component
    :is="rootElementType"
    class="table-cell"
    :class="customClasses"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <div
          class="cell-content full-width-cell-content"
          :class="cellContentCustomClasses"
        >
          {{ displayValue }}
        </div>
        <div class="right-of-input">
          <slot name="right-of-input" />
        </div>
      </div>
    </div>
  </component>
</template>
<script>
import isString from '@/store/helpers/isString';
import formatNumber from '@/store/helpers/formatNumber';
import isParsableNumber from '@/store/helpers/isParsableNumber';

export default {
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    cellContentCustomClasses: {
      type: Object,
      default: () => ({}),
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    value: {
      validator: (propValue) => Number.isFinite(propValue) || isString(propValue),
      required: true,
    },
    overrideDisplayValue: {
      type: String,
      default: '',
    },
    rootElementType: {
      type: String,
      default: 'td',
    },
  },
  computed: {
    displayValue() {
      if (this.overrideDisplayValue) {
        return this.overrideDisplayValue;
      }

      return isParsableNumber(this.value) ? formatNumber(this.value) : '';
    },
  },
};
</script>
