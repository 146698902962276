<template>
  <td
    class="table-cell aka-table-cell"
    :class="Object.assign(customClasses, {'is-selected': isCellSelected, 'is-highlighted': isCellHighlighted})"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <multi-tag-input
          :model-value="value"
          :read-only-values="entityAkas"
          @inputBlur="onValueInputBlur"
          @update:modelValue="updateValue"
        />
      </div>
      <alert-circle-icon
        v-if="validationError"
        v-tooltip="`${validationError}`"
        size="1.2x"
        stroke="red"
      />
    </div>
  </td>
</template>
<script>
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import MultiTagInput from '@/components/forms/MultiTagInput.vue';
import isSet from '@/store/helpers/isSet';
import { validateManyEntityNames } from '@/store/helpers/request/validators/validateMetrics';
import ValidationException from '@/store/helpers/request/exceptions/ValidationException';

export default {
  components: { MultiTagInput, AlertCircleIcon },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    validators: {
      type: Array,
      default: () => [],
    },
    entityAkas: {
      type: Array,
      required: true,
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'update:modelValue'],
  data() {
    return {
      value: [],
    };
  },
  computed: {
    validationError() {
      if (this.valueChanged()) {
        try {
          validateManyEntityNames(this.value, this.validators);
        } catch (e) {
          if (e instanceof ValidationException) {
            return e.message;
          }
          throw e;
        }
      }
      return null;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal, oldVal) {
        const valueHasChanged = isSet(newVal) && newVal?.toString() !== oldVal?.toString();
        if (valueHasChanged) {
          this.value = newVal;
        }
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.value = newValue;
    },
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    valueChanged() {
      return this.value.toString() !== this.modelValue.toString();
    },
    onValueInputBlur() {
      this.$log.info('AKACell onValueInputBlur', this.value, this.value !== this.modelValue);

      if (this.valueChanged() && !this.validationError) {
        this.$emit('update:modelValue', this.value);
      }
    },
  },
};
</script>
