<template>
  <modal
    :show="show"
    title="Change Document Type"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p>
        You have selected this document to be re-classified to:
      </p>
      <b> {{ documentType.display }} </b>
      <p>
        The document will be reprocessed and shall appear in the verification hub when it's ready for your review.
      </p>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY"
          message="CONFIRM"
          :custom-classes="{'top-nav-item': true}"
          :is-disabled="updateDocumentTypeIsLoading"
          :is-loading="updateDocumentTypeIsLoading"
          @onClick="confirmUpdate"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    ActionButton,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    documentType: {
      type: Object,
      required: true,
    },
    updateDocumentTypeIsLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirmUpdate'],
  methods: {
    close() {
      this.$emit('close');
    },
    confirmUpdate() {
      this.$emit('confirmUpdate');
    },
  },
};
</script>
