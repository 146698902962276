<template>
  <td
    class="table-cell"
    :class="Object.assign(
      customClasses,
      {
        'is-selected': isCellSelected,
        'is-highlighted': isCellHighlighted,
        'table-cell-dropdown': validatorOptions
      }
    )"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <div
          v-show="!isVerified"
          class="left-of-input"
        >
          <confidence-bars :confidence="confidence" />
        </div>
        <div>
          <currency-selector
            :custom-classes="{'currency-selector-verified': isVerified}"
            :options="currencyOptions"
            :model-value="currencyValue"
            select-placeholder="-"
            @update:modelValue="onCurrencyUpdated"
          />
        </div>
        <vue-multi-select
          v-if="validatorOptions"
          :model-value="value"
          :options="validatorOptions"
          placeholder="Not Reported"
          @update:model-value="updateOptions"
          @open="onCellSelected"
        />
        <input
          v-else
          type="text"
          class="cell-content numeric-cell-content"
          placeholder="Not Reported"
          :value="value"
          @input="updateValue($event.target.value)"
          @blur="onValueInputBlurred"
        >
        <alert-circle-icon
          v-if="validationError"
          v-tooltip="`${validationError}`"
          size="1.2x"
          stroke="red"
        />
      </div>
      <metric-verify-toggle
        :show-toggle="isCellSelected"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </td>
</template>
<script>
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import VueMultiSelect from 'vue-multiselect';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import CurrencySelector from '@/components/verify/CurrencySelector.vue';
import { currencies, currencyMappingToDisplay } from '@/store/helpers/mapping/currencies';
import useUpdateCellValue from '@/hooks/useNumericUpdateCellValue';
import ConfidenceBars from '@/components/general/ConfidenceBars.vue';
import { getNumericValidatorOptions } from '@/store/helpers/metrics/metricValidators';

export default {
  components: {
    ConfidenceBars, CurrencySelector, MetricVerifyToggle, VueMultiSelect, AlertCircleIcon,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      validator: (propValue) => Number.isFinite(propValue) || propValue === null,
      required: true,
    },
    modelCurrencyValue: {
      type: String,
      required: true,
    },
    confidence: {
      type: Number,
      default: null,
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'update:modelValue', 'update:currencyModelValue'],
  setup(props, context) {
    const {
      value, updateValue, onValueInputBlurred, updateOptions, validationError,
    } = useUpdateCellValue(props, context);
    return {
      value, updateValue, onValueInputBlurred, updateOptions, validationError,
    };
  },
  data() {
    return {
      currencyOptions: currencyMappingToDisplay,
      currencyValue: '',
    };
  },
  computed: {
    currencySymbol() {
      return currencies[this.currencyValue]?.displayCountry ?? '';
    },
    validatorOptions() {
      return getNumericValidatorOptions(this.validators);
    },
  },
  watch: {
    modelCurrencyValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.currencyValue = newVal;
        }
      },
    },
  },
  methods: {
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    onCurrencyUpdated(newValue) {
      this.currencyValue = newValue;
      this.$emit('update:currencyModelValue', this.currencyValue);
    },
  },
};
</script>
