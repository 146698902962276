<template>
  <td
    class="table-cell"
    :class="Object.assign(
      customClasses,
      {
        'is-selected': isCellSelected,
        'is-highlighted': isCellHighlighted,
        'table-cell-dropdown': validatorOptions
      }
    )"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <div
          v-show="!isVerified"
          class="left-of-input"
        >
          <confidence-bars :confidence="confidence" />
        </div>
        <vue-multi-select
          v-if="validatorOptions"
          :model-value="currentFormattedDate"
          :options="validatorOptions"
          placeholder="Not Reported"
          @update:model-value="updateOptions"
          @open="onCellSelected"
        />
        <date-input
          v-else
          :is-value-set="valueIsSet()"
          :custom-classes="{}"
          :date-format-str="dateFormat.format"
          :dd="dd"
          :mm="mm"
          :yyyy="yyyy"
          @updateDD="updateDD"
          @updateMM="updateMM"
          @updateYYYY="updateYYYY"
          @blur="onValueInputBlur"
        />
        <alert-circle-icon
          v-if="validationError"
          v-tooltip="`${validationError}`"
          size="1.2x"
          stroke="red"
        />
      </div>
      <metric-verify-toggle
        :show-toggle="isCellSelected"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </td>
</template>
<script>
import VueMultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import isString from '@/store/helpers/isString';
import DateInput from '@/components/verify/cellTypes/DateInput.vue';
import ConfidenceBars from '@/components/general/ConfidenceBars.vue';
import { validateMetric } from '@/store/helpers/request/validators/validateMetrics';
import ValidationException from '@/store/helpers/request/exceptions/ValidationException';

export default {
  components: {
    ConfidenceBars,
    DateInput,
    MetricVerifyToggle,
    AlertCircleIcon,
    VueMultiSelect,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      validator: (propValue) => isString(propValue) || propValue === null,
      required: true,
    },
    confidence: {
      type: Number,
      default: null,
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'update:modelValue'],
  data() {
    return {
      dd: '',
      mm: '',
      yyyy: '',
    };
  },
  computed: {
    ...mapGetters({
      dateFormat: 'localisation/dateFormat',
    }),
    fullValue() {
      return this.valueIsSet() ? `${this.yyyy}-${this.mm}-${this.dd}` : null;
    },
    validDate() {
      // No need to check if value has not changed.
      if (!this.valueChanged()) {
        return true;
      }

      // Check if value is set.
      if (!this.valueIsSet()) {
        return true;
      }
      if (!this.valueIsSet(false)) {
        return false;
      }

      // Initialize date values.
      const year = parseInt(this.yyyy, 10);
      const month = parseInt(this.mm, 10);
      const day = parseInt(this.dd, 10);

      // Check the ranges of month and year.
      if (year < 1000 || month === 0 || month > 12) {
        return false;
      }

      // Adjust for leap years and month length.
      const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
      }

      // Check the range of the day.
      return day > 0 && day <= monthLength[month - 1];
    },
    validatorOptions() {
      const result = [];
      if (this.validators.length) {
        this.validators.forEach((va) => {
          if (va.type === 'INCLUDE' && 'options' in va) {
            va.options.forEach((option) => {
              result.push(this.formatDateString(option));
            });
          }
        });
      }
      return result.length ? result : null;
    },
    currentFormattedDate() {
      if (this.fullValue === null) {
        return null;
      }
      return this.formatDateString(this.fullValue);
    },
    validationError() {
      if (this.valueChanged()) {
        if (!this.validDate && this.valueIsSet(false)) {
          return `Date should be valid in format ${this.dateFormat.format}`;
        }
        try {
          validateMetric(this.fullValue, this.validators);
        } catch (e) {
          if (e instanceof ValidationException) {
            return e.message;
          }
          throw e;
        }
      }
      return null;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal !== null) {
            this.fillFields(newVal);
          } else {
            this.resetDate();
          }
        }
      },
    },
  },
  methods: {
    /**
     * Check if value of the date is set.
     * @param all (bool, default=true): should all inputs be filled.
     */
    valueIsSet(all = true) {
      let allInputsBlank;
      if (all) {
        allInputsBlank = this.dd === '' && this.mm === '' && this.yyyy === '';
      } else {
        allInputsBlank = this.dd === '' || this.mm === '' || this.yyyy === '';
      }
      return !allInputsBlank;
    },
    fillFields(newDate) {
      /** @param newDate string "YYYY-MM-DD */
      const ds = newDate.split('-');
      this.resetDate();
      if (ds.length > 0) {
        [this.yyyy] = ds;
      }
      if (ds.length > 1) {
        [, this.mm] = ds;
      }
      if (ds.length > 2) {
        [,, this.dd] = ds;
      }
    },
    updateDD(value) {
      this.dd = value;
    },
    updateMM(value) {
      this.mm = value;
    },
    updateYYYY(value) {
      this.yyyy = value;
    },
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    valueChanged() {
      return this.fullValue !== this.modelValue;
    },
    onValueInputBlur() {
      if (this.valueChanged() && this.validDate && !this.validationError) {
        this.$log.info('DateCell update on blur', this.value, this.modelValue, this.fullValue);
        this.$emit('update:modelValue', this.fullValue);
      }
    },
    resetDate() {
      this.dd = '';
      this.mm = '';
      this.yyyy = '';
    },
    /**
     * Convert base date to needed format.
     * @param date format YYYY-MM-DD
     */
    formatDateString(date) {
      const ds = date.split('-');
      if (this.dateFormat.format === 'DD/MM/YYYY') {
        return `${ds[2]}/${ds[1]}/${ds[0]}`;
      }
      return `${ds[1]}/${ds[2]}/${ds[0]}`;
    },
    updateOptions(newVal) {
      if (!newVal) {
        this.resetDate();
      } else {
        const ds = newVal.split('/');
        if (this.dateFormat.format === 'DD/MM/YYYY') {
          [this.dd] = ds;
          [, this.mm] = ds;
          [,, this.yyyy] = ds;
        } else {
          [this.mm] = ds;
          [, this.dd] = ds;
          [,, this.yyyy] = ds;
        }
      }
      this.onValueInputBlur();
    },
  },
};
</script>
