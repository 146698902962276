<template>
  <div
    v-if="currentEntity && hasEntityInformation()"
    class="wrap-collapsible"
  >
    <input
      :id="uuid"
      class="toggle"
      type="checkbox"
    >
    <label
      :for="uuid"
      class="lbl-toggle"
    >
      Show detail..
    </label>
    <div class="collapsible-content">
      <div class="content-inner">
        <p> Entity information: </p>
        <ul
          v-if="currentEntity.fins"
          style="margin-left: 20px; list-style-type: circle"
        >
          <li v-if="currentEntity.clientIdentifier && !clientIdentifierInFins()">
            Default : {{ currentEntity.clientIdentifier }}
          </li>
          <li
            v-for="(fin, idx) in currentEntity.fins"
            :key="idx"
          >
            {{ fin.display }} <a v-if="fin.clientIdentifier"> ID: {{ fin.clientIdentifier }} </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    <p> No entity information to show </p>
  </div>
</template>
<script>
let uuid = 0;

export default {
  props: {
    currentEntity: {
      type: [Object, String],
      required: true,
    },
  },
  beforeCreate() {
    // For creating unique toggles.
    this.uuid = uuid.toString();
    uuid += 1;
  },
  methods: {
    hasEntityInformation() {
      return this.currentEntity.clientIdentifier !== null || this.currentEntity.fins.length !== 0;
    },
    clientIdentifierInFins() {
      const { fins } = this.currentEntity;
      for (let i = 0; i < fins.length; i++) {
        if (fins[i].clientIdentifier === this.currentEntity.clientIdentifier) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
