<template>
  <modal
    :show="show"
    title="Change Currency"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p class="">
        Change the currency of the entire table to:
      </p>
      <select-input
        v-model="selectedCurrency"
        select-id="change-table-currency-selector"
        name="change currency for table"
        :options="currencyOptions"
      />
      <br>
      <label
        for="currency-table-checkbox"
        class="modal-checkbox"
      >
        Preserve new value for further changes
        <input
          id="currency-table-checkbox"
          v-model="preserveTableCurrency"
          type="checkbox"
        >
        <span class="checkmark" />
      </label>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG_BORDERLESS"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          message="CHANGE"
          :custom-classes="{'top-nav-item': true}"
          :is-disabled="selectedCurrency === ''"
          @onClick="onChangeCurrency"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import { currencyMappingToDisplayOption } from '@/store/helpers/mapping/currencies';
import SelectInput from '@/components/forms/SelectInput.vue';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    SelectInput,
    ActionButton,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirmUpdateTableCurrency'],
  data() {
    return {
      selectedCurrency: 'AUTO',
      preserveTableCurrency: false,
      currencyOptions: currencyMappingToDisplayOption,
    };
  },
  methods: {
    reset() {
      this.selectedCurrency = 'AUTO';
      this.preserveTableCurrency = false;
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    onChangeCurrency() {
      this.$emit('confirmUpdateTableCurrency', this.selectedCurrency, this.preserveTableCurrency);
      this.reset();
    },
  },
};
</script>
