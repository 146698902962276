import {
  computed, onMounted, onUnmounted, ref, toRefs, watch,
} from 'vue';
import clonedeep from 'lodash.clonedeep';
import { useRoute } from 'vue-router';
import { metricMapping } from '@/store/helpers/mapping/metrics';
import isSet from '@/store/helpers/isSet';
import roundNumber from '@/store/helpers/roundNumber';
import {
  createRowStateChange,
  deleteRowStateChange,
  realiseStateChange,
  updateAkaValueStateChange,
  updateConfidenceStateChange,
  updateCurrencyValueStateChange,
  updateLocationStateChange,
  updateSelectedEntityValueStateChange,
  updateValueStateChange,
  updateVerifyStateChange,
  preservedTableCurrency,
  cudTypes,
  stateChangeTypes,
} from '@/store/helpers/metricTableState/stateChange';
import {
  resetRedoChanges,
  undoLastStateChange,
  isNestedChange,
  adjustForAddedRow,
} from '@/store/helpers/metricTableState/undoRedo';
import toNumber from '@/store/helpers/toNumber';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';
import { allowedStates } from '@/store/helpers/storeState';
import annotationToNormalisedCurrency from '@/store/helpers/annotations/annotationToNormalisedCurrency';
import sumFloats from '@/store/helpers/sumFloats';
import annotationToNormalisedDate from '@/store/helpers/annotations/annotationToNormalisedDate';
import annotationToNormalisedNumberWithoutExponent from '@/store/helpers/annotations/annotationToNormalisedNumberWithoutExponent';
import { getVerifyExportValueFromDp } from '@/store/helpers/metrics/exportDatapointValue';
import { currencies } from '@/store/helpers/mapping/currencies';
import { logger } from '@/store/logger';
import { checkMetricContainsData } from '@/store/helpers/metrics/metricValidators';
import { jumpToMetric, getJumpToFilterGroupIndex } from '@/store/helpers/metrics/jumpToMetric';

const MAX_CONFIDENCE = 1;

export default (props, context, store, toast) => {
  const route = useRoute();

  // Temporary: to default show not reported for certain clients.
  const startFilterShowNotReported = () => {
    if (/iqeq/.test(window.location.href)) {
      return false;
    }
    return true;
  };

  // Reactive props
  const breadcrumbIdx = ref(props.breadcrumbIdx);
  const documentRequestId = ref(props.documentRequestId);
  const isRepeating = ref(props.isRepeating);
  const tableIdx = ref(props.tableIdx);
  const verifyMenuActionTriggers = toRefs(props.verifyMenuActionTriggers);

  // Data
  const addEntityOnboardingName = ref('');
  const channelNodeSelected = ref(null);
  const currentFilterGroupIdx = ref(0);
  const columnHeaders = ref([]);
  const columnHeaderOptions = ref([]);
  const columnOptionCurrencyChangeColIdx = ref(0);
  const filterShowNotReported = ref(startFilterShowNotReported());
  const filterOptions = ref([]);
  const lastSuccessfulAutoSaveStateChangeIndex = ref(null);
  const metrics = ref([]);
  const newEntityModalRowIdx = ref(0);
  const entityModalMetricFin = ref('');
  const entityModalMetricValidators = ref([]);
  const renamingEntityModalRowIdx = ref(0);
  const renamingEntityModalColIdx = ref(0);
  const renamingEntityModalRowValue = ref('');
  const rows = ref([]);
  const selectedCells = ref([]); // array of array of booleans
  const selectedHeaders = ref([]);
  const selectedRowHeaders = ref([]);
  const showEntityModal = ref(false);
  const showColumnOptionCurrencyChangeModal = ref(false);
  const showUpdateTableCurrencyModal = ref(false);
  const tableStateChanges = ref([]);
  const rowStatus = ref(allowedStates.IS_READY);
  const isTransposed = ref(false);
  const showConfidenceIndicators = ref(true);
  const redoChanges = ref([]);

  // Computed properties:
  const verifyDocumentStoreIsReady = computed(() => store.getters['verifyDocument/isReady']);
  const selectedLanguage = computed(() => store.getters['annotations/selectedLanguage']);
  // isActive cannot be passed from parental component, if there is a chance that the parental component may be deactivated
  const isActive = computed(() => String(route.params.breadcrumbIndex) === String(breadcrumbIdx.value));
  const changesMadeSinceLastAutoSave = computed(
    () => {
      const stateChangesLength = tableStateChanges.value.length;
      if (stateChangesLength === 0) {
        return false;
      }
      if (lastSuccessfulAutoSaveStateChangeIndex.value === null) {
        return true;
      }

      return lastSuccessfulAutoSaveStateChangeIndex.value < stateChangesLength - 1;
    },
  );
  const hasEntityNameRowHeaders = computed(
    () => {
      if (!columnHeaders.value.length) {
        return false;
      }
      const firstHeader = columnHeaders.value[0];
      return Object.keys(firstHeader) && firstHeader.metricType === 'ENTITY_NAME';
    },
  );
  const showColumnIndices = computed(
    /* Abstracting filters out once we have multiple value filters (E.g. confidence & verified status) */
    () => {
      if (columnHeaders.value.length === 0) {
        return [];
      }

      let columnIndicesToShow;

      // Filter out columns if they have no values present
      const showNotReportedColumns = filterShowNotReported.value;
      if (showNotReportedColumns || rows.value.length === 0) {
        columnIndicesToShow = new Array(columnHeaders.value.length).fill(true);
      } else {
        columnIndicesToShow = new Array(columnHeaders.value.length).fill(false);
        rows.value.forEach((row) => {
          row.forEach((metricIdx, rowIdx) => {
            // Skip columns that we've already decided need to be shown
            if (columnIndicesToShow[rowIdx] === false) {
              columnIndicesToShow[rowIdx] = checkMetricContainsData(metrics.value[metricIdx]);
            }
          });
        });
      }

      // Filter out columns by filter group:
      const isFilteringByFilterGroup = filterOptions.value.length > 1;
      if (isFilteringByFilterGroup) {
        columnHeaders.value.forEach((colHeader, i) => {
          if (
            columnIndicesToShow[i] === true
            && colHeader.metricType !== 'ENTITY_NAME'
            && filterOptions.value[currentFilterGroupIdx.value] !== colHeader.filterGroup
            && colHeader.filterGroup !== ''
            && (colHeader.metricType !== 'AKA' || colHeader.filterGroup !== null)
          ) {
            columnIndicesToShow[i] = false;
          }
        });
      }

      return columnIndicesToShow;
    },
  );
  const showRowIndices = computed(
    () => {
      const showNotReportedRows = filterShowNotReported.value;

      let rowIndicesToShow;
      if (showNotReportedRows) {
        rowIndicesToShow = new Array(rows.value.length).fill(true);
      } else {
        rowIndicesToShow = new Array(rows.value.length).fill(false);
        rows.value.forEach((row, rowIdx) => {
          row.forEach((metricIdx) => {
            // Skip rows that we've already decided need to be shown
            if (rowIndicesToShow[rowIdx] === false) {
              rowIndicesToShow[rowIdx] = checkMetricContainsData(metrics.value[metricIdx]);
            }
          });
        });
      }

      return rowIndicesToShow;
    },
  );
  const numColumnIndicesHidden = computed(() => showColumnIndices.value.filter((c) => !c).length);
  const cellVerificationFlags = computed(() => {
    if (!columnHeaders.value.length) {
      return [];
    }
    if (rows.value.length === 0) {
      return new Array(columnHeaders.value.length).fill(false);
    }

    return rows.value.map((r) => r.map((metricIdx) => metrics.value[metricIdx].isVerified));
  });
  const columnVerificationFlags = computed(() => {
    // Flags determining whether each column is completely verified
    if (!columnHeaders.value.length) {
      return [];
    }
    if (rows.value.length === 0) {
      return new Array(columnHeaders.value.length).fill(false);
    }
    const colVerificationFlags = new Array(columnHeaders.value.length).fill(true);
    cellVerificationFlags.value.forEach((row) => {
      row.forEach((flag, colIdx) => {
        // If this column's flag is already false, skip it. (No vue reactivity changes)
        // If this is a repeating table then we don't want the first column header to be verified. (Row header verified status
        // is determined by all of the cells in a row being verified rather than the header's status itself)
        if (!flag || (isRepeating.value && hasEntityNameRowHeaders.value && colIdx === 0)) {
          colVerificationFlags[colIdx] = false;
        }
      });
    });

    return colVerificationFlags;
  });
  const numVisibleColumns = computed(() => showColumnIndices.value.filter(Boolean).length);
  const numVisibleRows = computed(() => showRowIndices.value.filter(Boolean).length);
  const metricTableIsLoading = computed(() => rowStatus.value === allowedStates.IS_LOADING);
  const calculatedTotals = computed(() => columnHeaders.value.map((colHeader, colIdx) => {
    if (colHeader.metricType === 'CURRENCY') {
      const colValues = rows.value.map((r) => metrics.value[r[colIdx]].datapoints[0].value);
      return sumFloats(colValues);
    }
    return '';
  }));
  const selectedCellCoords = computed(() => {
    const selectedCoords = [];
    for (let i = 0; i < selectedCells.value.length; i++) {
      for (let j = 0; j < selectedCells.value[i].length; j++) {
        const isCellSelected = selectedCells.value[i][j];
        if (isCellSelected) {
          selectedCoords.push([i, j]);
        }
      }
    }
    return selectedCoords;
  });
  const getSelectedMetric = () => {
    const allSelectedCoords = selectedCellCoords.value;
    if (allSelectedCoords.length !== 1) {
      return null; // No cells selected or more than 1 cell selected
    }
    const selectedCoords = allSelectedCoords[0];
    const adjustedColIdx = selectedCoords[1];
    const metricId = rows.value[selectedCoords[0]][adjustedColIdx];

    return metrics.value[metricId];
  };
  const getDisplayValueForDatapoint = (datapoint, metricType) => {
    logger.debug('getDisplayValueForDatapoint', datapoint.value, metricType);
    return getVerifyExportValueFromDp(datapoint, metricType, store.getters['localisation/dateFormat']);
  };
  const selectedCellAlternativeAnswers = computed(() => {
    const metric = getSelectedMetric();
    if (metric === null) {
      return []; // No cells selected or more than 1 cell selected
    }

    return metric.datapoints.map((d) => getDisplayValueForDatapoint(d, metric.metricType));
  });
  const showCalculatedTotals = computed(() => isRepeating.value && rows.value.length > 0);
  const hasRedoChanges = computed(() => redoChanges.value.length !== 0);

  // Methods
  const createTableStateChange = (stateChange, redoReset = true) => {
    realiseStateChange(stateChange, rows.value, metrics.value);
    tableStateChanges.value.push(stateChange);
    store.commit('verifyDocument/SET_AUTO_SAVE_STATUS', allowedStates.IS_PENDING);
    if (redoReset) {
      resetRedoChanges(store, redoChanges.value);
    }
  };
  const generateInitialSelectedCells = (initialRows) => {
    /** @param initialRows Array All rows of table (minus first column as it is not selectable) */
    if (!initialRows.length || !initialRows[0].length) {
      return [];
    }

    return initialRows.map((r) => Array(r.length).fill(false));
  };
  const generateInitialSelectedHeaders = (initialHeaders) => {
    if (!initialHeaders.length) {
      return [];
    }

    return new Array(initialHeaders.length).fill(false);
  };
  const generateInitialSelectedRowHeaders = (initialRows) => {
    if (!initialRows.length) {
      return [];
    }

    return new Array(initialRows.length).fill(false);
  };
  const resetSelectedCellsAndHeaders = (withRows, withHeaders) => {
    selectedCells.value = generateInitialSelectedCells(withRows);
    selectedHeaders.value = generateInitialSelectedHeaders(withHeaders);
    selectedRowHeaders.value = generateInitialSelectedRowHeaders(withRows);
    store.commit('verifyDocument/SET_CAN_VERIFY_ROW_COLUMN', { canVerifyRow: false, canVerifyColumn: false });
  };
  const getStateChangesToSave = () => {
    if (lastSuccessfulAutoSaveStateChangeIndex.value === null) {
      return tableStateChanges.value;
    }

    return tableStateChanges.value.slice(lastSuccessfulAutoSaveStateChangeIndex.value + 1);
  };
  const autoSaveWithoutChanges = () => store.dispatch('verifyDocument/autoSaveWithoutChanges');
  const sendAutoSaveRequest = () => {
    if (!verifyDocumentStoreIsReady.value) {
      const msg = 'Metric table is not ready';
      toast.error(msg);
      return Promise.reject(msg);
    }
    const lastStateChangeIndex = tableStateChanges.value.length - 1;
    return store.dispatch('verifyDocument/saveTable', {
      tableIdx: tableIdx.value,
      breadcrumbIdx: breadcrumbIdx.value,
      stateChanges: getStateChangesToSave(),
    })
      .then((resp) => {
        logger.debug(`AutoSave successful (stateChangeIndex, old: ${lastSuccessfulAutoSaveStateChangeIndex.value}, new: ${lastStateChangeIndex})`);
        lastSuccessfulAutoSaveStateChangeIndex.value = lastStateChangeIndex;
        store.commit('verifyDocument/SET_AUTO_SAVE_STATUS', allowedStates.IS_READY);
        return resp;
      })
      .catch((e) => {
        logger.error(e);
        toast.error('Error saving metrics');
        rowStatus.value = allowedStates.IS_READY;
        throw e;
      });
  };
  /**
   * Save individual state change without affecting tableStateChanges,
   * but triggering.
   * @param {*} stateChange state change to be committed.
   */
  const sendAutoSaveRequestForStateChange = (stateChange) => {
    if (!verifyDocumentStoreIsReady.value) {
      const msg = 'Metric table is not ready';
      toast.error(msg);
      return Promise.reject(msg);
    }
    const currentAutoSaveStatus = store.getters['verifyDocument/autoSaveStatus'];
    return store.dispatch('verifyDocument/saveTable', {
      tableIdx: tableIdx.value,
      breadcrumbIdx: breadcrumbIdx.value,
      stateChanges: [stateChange],
    })
      .then((resp) => {
        logger.debug('AutoSave for state change successful');
        store.commit('verifyDocument/SET_AUTO_SAVE_STATUS', currentAutoSaveStatus);
        return resp;
      })
      .catch((e) => {
        logger.error(e);
        toast.error('Error saving metrics');
        rowStatus.value = allowedStates.IS_READY;
        throw e;
      });
  };
  const autoSave = async (fullSave = false) => {
    logger.debug('AutoSave triggered!');
    resetRedoChanges(store, redoChanges.value);
    if (changesMadeSinceLastAutoSave.value) {
      return sendAutoSaveRequest();
    }
    if (fullSave) {
      return autoSaveWithoutChanges();
    }
    return null;
  };
  const onSaveTriggered = (fullSave = false) => {
    autoSave(fullSave);
    store.dispatch('entityNameMetrics/updateForTable', { tableIdx: tableIdx.value, rows: rows.value, metrics: metrics.value }, { root: true });
  };
  // Methods -- correction
  const onRenameEntityAt = (rowIdx, colIdx, rowValue, metricFin, metricValidators) => {
    logger.debug('onRenameEntityAt:', rowIdx);
    renamingEntityModalRowIdx.value = rowIdx;
    renamingEntityModalRowValue.value = rowValue;
    newEntityModalRowIdx.value = null;
    renamingEntityModalColIdx.value = colIdx;
    entityModalMetricFin.value = metricFin;
    entityModalMetricValidators.value = metricValidators;
    showEntityModal.value = true;
  };
  const onColumnOptionCurrencyChangeClose = () => {
    showColumnOptionCurrencyChangeModal.value = false;
  };
  const onUpdateTableCurrencyClose = () => {
    showUpdateTableCurrencyModal.value = false;
  };
  const updateMetricIdsForRow = (backendMetrics, rowIdx) => {
    rows.value[rowIdx].forEach((metricIdx) => {
      const metric = metrics.value[metricIdx];
      metric.id = backendMetrics[metric.fin].id;
      logger.debug('Updated metric id for', metric.fin, 'to:', metric.id);
    });
  };
  const onEntityModalClose = () => {
    showEntityModal.value = false;
  };
  /**
   * @param {boolean} checkValidated Set to false if the metric does not need to
   *    be validated to update the currency.
   *    Needs to be set to true for when updating whole table currency - we want
   *    to not touch validated metrics currencies in this context.
   */
  const updateCellCurrencyValue = (rowIdx, colIdx, newValue, singleChange = true, checkValidated = true) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    if (!checkValidated || !metric.isVerified) {
      const oldVal = metric.datapoints[0].effectors.currency;
      const stateChange = updateCurrencyValueStateChange(metric.id, rowIdx, colIdx, oldVal, newValue, singleChange);
      return stateChange;
    }
    return null;
  };
  const onCellCurrencyValueUpdated = (rowIdx, colIdx, newValue, singleChange = true, checkValidated = true) => {
    const stateChange = updateCellCurrencyValue(rowIdx, colIdx, newValue, singleChange, checkValidated);
    if (stateChange) {
      createTableStateChange(stateChange);
    }
  };
  /**
   * Update the value of an entity's akas.
   * It will replace the old values with the new values.
   * @param {*} rowIdx metric row id.
   * @param {*} colIdx metric col id (usually 1 for this).
   * @param {*} newValue array of str of new akas.
   * @param {bool} setEntityAkas set to true to overwrite entity akas in addition to the
   *               datapoint akas.
   * @returns stateChange instance.
   */
  const updateCellAkas = (rowIdx, colIdx, newValue, singleChange = true, setEntityAkas = false) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Metric AKA value updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');

    const oldVal = metric.datapoints[0].effectors.akas || [];
    const stateChange = updateAkaValueStateChange(metric.id, rowIdx, colIdx, oldVal, newValue, singleChange);
    realiseStateChange(stateChange, rows.value, metrics.value);

    // Set entity akas.
    if (setEntityAkas) {
      logger.debug('Setting entity akas:', newValue);
      metric.entityAkas = newValue;
    }
    return stateChange;
  };
  const onCellAkasUpdated = (rowIdx, colIdx, newValue, singleChange = true) => {
    const stateChange = updateCellAkas(rowIdx, colIdx, newValue, singleChange);
    createTableStateChange(stateChange);
  };
  const onNewRowAdded = async (rowIdx, newEntityName) => {
    logger.debug('Creating new row at index:', rowIdx, 'with name:', newEntityName);

    // Set loading blocker.
    rowStatus.value = allowedStates.IS_LOADING;
    await new Promise((resolve) => setTimeout(resolve, 1));

    const stateChange = createRowStateChange(rowIdx, newEntityName);
    realiseStateChange(stateChange, rows.value, metrics.value, columnHeaders.value);
    resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);

    // Attempt to save, update row id with backend id if successful, otherwise revert.
    await sendAutoSaveRequestForStateChange(stateChange)
      .then((response) => {
        logger.debug('Syncing new ids saved on the backend to MetricTable, response:', response);

        // Currently, only supporting saving one new repeating group at a time
        const backendCreatedMetrics = Object.values(response.created)[0].metrics;
        logger.debug('backendCreatedMetrics', Object.values(response.created)[0], backendCreatedMetrics);
        updateMetricIdsForRow(backendCreatedMetrics, rowIdx);

        // Update state changes for added row.
        adjustForAddedRow(tableStateChanges.value, redoChanges.value, rowIdx);

        // Add akas if there are any.
        const { akas } = Object.values(backendCreatedMetrics)[0];
        if (akas) {
          updateCellAkas(rowIdx, 1, akas, true, true);
        }

        // Update new rows currency if needed.
        if (preservedTableCurrency.value !== 'AUTO') {
          columnHeaders.value.forEach((column, columnIdx) => {
            if (column.metricType === 'CURRENCY') {
              updateCellCurrencyValue(rowIdx, columnIdx, preservedTableCurrency.value);
            }
          });
        }
      })
      .catch((e) => {
        logger.error('Adding new row failed: ', e);
        rows.value.splice(rowIdx);
      });
    rowStatus.value = allowedStates.IS_READY;
    logger.debug('Finished onNewRowAdded at:', rowIdx);
  };
  const addNewRowAt = async (rowIdx) => {
    logger.debug(`Adding new row at ${rowIdx}`);
    if (hasEntityNameRowHeaders.value) {
      showEntityModal.value = true;
      newEntityModalRowIdx.value = rowIdx;
      renamingEntityModalRowValue.value = null;
      // TODO: Here we assume entity column is the first column.
      entityModalMetricFin.value = columnHeaders.value[0].fin;
      entityModalMetricValidators.value = columnHeaders.value[0].validators;
    } else {
      await onNewRowAdded(rowIdx, null, null);
    }
  };
  const updateTableCurrency = async () => {
    showUpdateTableCurrencyModal.value = true;
  };
  const deleteRowAt = async (rowIdx) => {
    logger.debug('deleting row at:', rowIdx, typeof rowIdx);

    // Get old metrics.
    const oldMetrics = [];
    rows.value.at(rowIdx).forEach((rowId) => {
      oldMetrics.push(metrics.value[rowId]);
    });

    const stateChange = deleteRowStateChange(rowIdx, oldMetrics);
    resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
    createTableStateChange(stateChange);
  };
  const rowIndexThatIsSelected = () => {
    // returns -1 if there are no rows that are (/or contain cells) that are selected
    const selectedRowIndex = selectedRowHeaders.value.indexOf(true);
    if (selectedRowIndex !== -1) {
      return selectedRowIndex;
    }
    for (const [rowIdx, row] of selectedCells.value.entries()) { // eslint-disable-line no-restricted-syntax
      if (row.includes(true)) {
        return rowIdx;
      }
    }
    return -1;
  };
  const deleteSelectedRow = () => {
    const rowIdxSelected = rowIndexThatIsSelected();
    if (rowIdxSelected === -1) {
      toast.warning('No entries/rows selected');
      return;
    }

    deleteRowAt(rowIdxSelected);
  };
  const onCellConfidenceUpdated = (rowIdx, colIdx, newValue) => {
    logger.debug('onCellConfidenceUpdated:', rowIdx, colIdx, rows.value);
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Metric dp confidence updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');

    const oldVal = metric.datapoints[0].confidence;
    const stateChange = updateConfidenceStateChange(metric.id, rowIdx, colIdx, oldVal, newValue);
    createTableStateChange(stateChange);
    logger.debug('Metric dp confidence updated for:', metricIndex, 'to:', newValue, '(old:', oldVal, ')');
  };
  const buildDefaultColumnHeaderOptions = (colHeaders) => colHeaders.map(() => ({}));
  const updateColumnHeaderOptionCurrency = (colIdx, newCurrency) => {
    logger.debug('updating column', colIdx, 'to currency:', newCurrency);
    const newSymbol = currencies[newCurrency]?.displayInline ?? newCurrency;
    columnHeaderOptions.value[colIdx].currencySymbol = newSymbol;
  };
  const updateStateForValue = (rowIdx, colIdx, newValue, singleChange = true) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Metric value updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');
    const oldVal = metric.datapoints[0].value;
    const stateChange = updateValueStateChange(metric.id, rowIdx, colIdx, oldVal, newValue, singleChange);
    createTableStateChange(stateChange);
    logger.debug('Metric value updated for:', metricIndex, 'to:', newValue, '(old:', oldVal, ')');
  };
  const broadcastResetSelected = () => {
    const selectedInfo = {
      annotations: [],
      documentRequestId: documentRequestId.value,
    };
    logger.debug('broadcasting (reset)', selectedInfo);
    if (!('BroadcastChannel' in window)) {
      const msg = 'PDF annotations are not supported for this browser';
      logger.error(msg);
      return;
    }
    const channelMetricSelected = new BroadcastChannel('metric-selected');
    channelMetricSelected.postMessage(selectedInfo);
    channelMetricSelected.close();
  };
  const onCellValueUpdated = (rowIdx, colIdx, newValue) => {
    logger.debug('onCellValueUpdated:', rowIdx, colIdx, rows.value);
    updateStateForValue(rowIdx, colIdx, newValue);
    onCellConfidenceUpdated(rowIdx, colIdx, MAX_CONFIDENCE);

    // Send broadcast to clear annotations if value is empty.
    if (!newValue && newValue !== 0) {
      broadcastResetSelected();
    }
  };
  /**
   * When renaming an entity, the backend may find the new name as an already
   * existing entity, in which case it sends the akas back.
   * @param {*} newEntityName str.
   */
  const onRenameEntityConfirmed = async (newEntityName) => {
    logger.debug('onRenameEntityConfirmed:', newEntityName, 'at rowIdx:', renamingEntityModalRowIdx.value);

    // Set loading state.
    rowStatus.value = allowedStates.IS_LOADING;
    await new Promise((resolve) => setTimeout(resolve, 1));

    // Update entity metric value.
    const metricIndex = rows.value[renamingEntityModalRowIdx.value][renamingEntityModalColIdx.value];
    const metric = metrics.value[metricIndex];
    logger.debug('Entity value updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');
    const oldVal = metric.datapoints[0].value;
    const stateChange = updateValueStateChange(
      metric.id, renamingEntityModalRowIdx.value, renamingEntityModalColIdx.value, oldVal, newEntityName,
    );
    realiseStateChange(stateChange, rows.value, metrics.value);

    // Send request to update row.
    await sendAutoSaveRequestForStateChange(stateChange)
      .then((response) => {
        // Add akas if there are any.
        const backendUpdatedMetrics = response.updated.at(-1).metrics;
        const { akas } = Object.values(backendUpdatedMetrics)[0];
        if (akas) {
          updateCellAkas(renamingEntityModalRowIdx.value, renamingEntityModalColIdx.value + 1, akas, true, true);
        }

        // Loop through each state change to save.
        // Remove any state changes from the stack that were meant to remove the entity text.
        // Since the entity was updated, we do not need to commit the previous removal.
        getStateChangesToSave().forEach((change) => {
          if (
            change.type === stateChangeTypes.VALUE
            && change.rowIdx === renamingEntityModalRowIdx.value
            && change.colIdx === renamingEntityModalColIdx.value
            && change.newVal === ''
          ) {
            logger.debug('Found entity removal state change; discarding it:', change);

            // Remove last state change with the above properties matching.
            const lastIndex = tableStateChanges.value.lastIndexOf(change);
            if (lastIndex !== -1) {
              // Splice 3 state changes from this: value, akas, confidence.
              tableStateChanges.value.splice(lastIndex, 3);

              // Set auto save status to ready if stack is now empty.
              if (!changesMadeSinceLastAutoSave.value) {
                store.commit('verifyDocument/SET_AUTO_SAVE_STATUS', allowedStates.IS_READY);
              }
            }
          }
        });
      })
      .catch((e) => {
        logger.error('Updating entity row failed: ', e);
      });
    rowStatus.value = allowedStates.IS_READY;
  };
  const onRemoveEntity = (colIdx) => {
    logger.debug('onRemoveEntity:', colIdx);
    onCellValueUpdated(0, colIdx, '');
    onCellAkasUpdated(0, colIdx + 1, [], false);
  };
  const onSubmitEntity = async ({ newEntityName, rowIdx }) => {
    onEntityModalClose();
    if (newEntityModalRowIdx.value === null) {
      await onRenameEntityConfirmed(newEntityName);
    } else {
      logger.debug('Adding new entity named:', newEntityName);
      await onNewRowAdded(rowIdx, newEntityName);
    }
  };
  const constructLocation = (rawNodeId, rawPage, isWord) => {
    if (!isSet(rawPage)) {
      return { annotationId: null, page: null };
    }

    const page = toNumber(rawPage);
    if (!isSet(rawNodeId)) {
      return { annotationId: null, page, isWord };
    }

    return { page, annotationId: String(rawNodeId), isWord };
  };
  const constructLocationsFromDatapoint = (datapoint) => [
    constructLocation(
      datapoint.nodeId,
      datapoint.page,
    ),
  ];
  const onCellLocationUpdated = (rowIdx, colIdx, newLocations, singleChange = false) => {
    logger.debug('onCellLocationUpdated:', rowIdx, colIdx, newLocations);
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Metric location updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');

    const oldVal = constructLocationsFromDatapoint(metric.datapoints[0]);
    const stateChange = updateLocationStateChange(metric.id, rowIdx, colIdx, oldVal, newLocations, singleChange);
    createTableStateChange(stateChange);

    logger.debug('Metric location updated for:', metricIndex, 'to:', newLocations, '(old:', oldVal, ')');
  };
  const onToggleCellVerified = (rowIdx, colIdx, singleChange = true) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Toggle cell verified for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');

    const oldVal = metric.isVerified;
    const stateChange = updateVerifyStateChange(metric.id, rowIdx, colIdx, oldVal, !oldVal, singleChange);
    createTableStateChange(stateChange);
  };
  const onCellSelectedEntityUpdated = (rowIdx, colIdx, newValue) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug('Metric selected entity updating for:', metricIndex, '(existing:', metrics.value[metricIndex], ')');

    const oldVal = metric.linkedEntity;
    const stateChange = updateSelectedEntityValueStateChange(metric.id, rowIdx, colIdx, oldVal, newValue);
    createTableStateChange(stateChange);

    logger.debug('Metric entity link value updated for:', metricIndex, 'to:', newValue, '(old:', oldVal, ')');
  };
  /**
   * Update cell exponent.
   * @param {*} rowIdx row id.
   * @param {*} colIdx column id.
   * @param {*} exponent exponent to update by.
   */
  const onCellScaleValueUpdated = (rowIdx, colIdx, exponent, singleChange) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];

    // Do not update verified or null metrics.
    if (metric.isVerified) {
      return;
    }
    const oldVal = metric.datapoints[0].value;
    if (!oldVal) {
      return;
    }

    // We use 9 as maximum decimal places, at 10+ you get situations
    // such as: 515.614 -> 515,614.0000000001
    const newVal = roundNumber(oldVal * 10 ** exponent, 9);

    // Only update the scale if the string conversion does not overflow.
    const newValStr = newVal.toString();
    if (!newValStr.includes('e')) {
      updateStateForValue(rowIdx, colIdx, newVal, singleChange);
    }
  };
  const updateTableScale = async (scale) => {
    let firstUpdate = true;
    columnHeaders.value.forEach((column, columnIdx) => {
      if (column.metricType === 'CURRENCY') {
        rows.value.forEach((row, rowIdx) => {
          onCellScaleValueUpdated(rowIdx, columnIdx, scale, firstUpdate);
          firstUpdate = false;
        });
      }
    });
  };
  /**
   * Update scale of selected cells or headers.
   * If a header is selected, no need to update the scale of cells.
   */
  const updateScaleSelectedCells = async (scale) => {
    logger.debug('updateScaleSelectedCells', scale, selectedHeaders, selectedCells);

    // Check to see if any headers are selected.
    let foundSelectedHeaders = false;
    let firstUpdate = true;
    selectedHeaders.value.forEach((selectedValue, colIdx) => {
      if (selectedValue) {
        rows.value.forEach((row, rowIdx) => {
          onCellScaleValueUpdated(rowIdx, colIdx, scale, firstUpdate);
          firstUpdate = false;
        });
        foundSelectedHeaders = true;
      }
    });
    if (foundSelectedHeaders) {
      return;
    }

    // Check to see if any cells are selected.
    selectedCells.value.forEach((cols, rowIdx) => {
      cols.forEach((selectedValue, colIdx) => {
        if (selectedValue) {
          onCellScaleValueUpdated(rowIdx, colIdx, scale, firstUpdate);
          firstUpdate = false;
        }
      });
    });
  };
  const onColumnOptionCurrencyChangeConfirmed = (currency) => {
    logger.debug('onColumnOptionCurrencyChangeConfirmed:', currency);
    showColumnOptionCurrencyChangeModal.value = false;
    rows.value.forEach((row, rowIdx) => {
      onCellCurrencyValueUpdated(
        rowIdx,
        columnOptionCurrencyChangeColIdx.value,
        currency,
        rowIdx === 0, // First change is a single change to undo/redo the whole column
      );
    });
    updateColumnHeaderOptionCurrency(columnOptionCurrencyChangeColIdx.value, currency);

    columnOptionCurrencyChangeColIdx.value = 0;
  };
  /**
   * Update table currency by looking at all columns and rows.
   * @param currency value from 'currencies.js'.
   * @param preserveCurrency keep currency after change.
   */
  const onUpdateTableCurrencyConfirmed = (currency, preserveCurrency) => {
    showUpdateTableCurrencyModal.value = false;
    let firstUpdate = true;
    if (currency !== 'AUTO') {
      columnHeaders.value.forEach((column, columnIdx) => {
        if (column.metricType === 'CURRENCY') {
          rows.value.forEach((row, rowIdx) => {
            onCellCurrencyValueUpdated(rowIdx, columnIdx, currency, firstUpdate);
            firstUpdate = false;
          });
        }
      });
    }
    if (preserveCurrency) {
      preservedTableCurrency.value = currency;
    }
  };
  /**
   * Verifies a group of metrics in a column based on the selected indices.
   */
  const setColumnVerificationStatus = (colIdx, isVerified) => {
    let firstUpdate = true;
    rows.value.forEach((row, rowIdx) => {
      const metric = metrics.value[row[colIdx]];
      if (metric.isVerified !== isVerified) {
        onToggleCellVerified(rowIdx, colIdx, firstUpdate);
        firstUpdate = false;
      }
    });
  };
  /**
   * Verifies a group of metrics in a row based on the selected indices.
   * Only verifies metrics in the current filter.
   */
  const setRowVerificationStatus = (rowIdx) => {
    let firstUpdate = true;
    rows.value[rowIdx].forEach((metricIdx, colIdx) => {
      if (showColumnIndices.value[colIdx]) {
        const metric = metrics.value[metricIdx];
        if (!metric.isVerified) {
          onToggleCellVerified(rowIdx, colIdx, firstUpdate);
          firstUpdate = false;
        }
      }
    });
  };
  const onToggleHeaderVerified = (colIdx) => {
    const isColVerified = columnVerificationFlags.value[colIdx];
    logger.debug('Toggle header verified for', colIdx, 'colVerified:', isColVerified);
    setColumnVerificationStatus(colIdx, !isColVerified);
  };
  // Methods -- selection
  const updateCellValueAndLoc = (rowIdx, colIdx, newValue, locations) => {
    logger.debug('updateCellValueAndLoc. Not yet saving nodeId and page', rowIdx, colIdx, newValue, locations);
    onCellValueUpdated(rowIdx, colIdx, newValue);
    onCellLocationUpdated(rowIdx, colIdx, locations);
  };
  const createLocationsFromAnnotations = (annotations) => annotations.map((a) => constructLocation(a.nid, a.page, a.isWord));
  const updateSelectedCellAnnotations = (rowIdx, colIdx, metric, annotations) => {
    /**
     * @param number rowIdx
     * @param number colIdx
     * @param Object metric
     * @param [{ nid: string, page: number, data: Object }] annotations
     */
    const data = annotations.length ? annotations[0].data : null; // Start off only looking at the annotation data for the first annotation

    switch (metric.metricType) {
      // If a node was clicked, and we've got a currency metric cell selected in the metric table,
      // see if the node had a normalised value/exponent.
      case metricMapping.MET_CURRENCY.backend: {
        const { value, currency } = annotationToNormalisedCurrency(data);
        const locations = createLocationsFromAnnotations(annotations);
        if (isSet(value)) {
          updateCellValueAndLoc(rowIdx, colIdx, value, locations);
          if (isSet(currency)) {
            logger.warn('Found currency as well!', currency);
            onCellCurrencyValueUpdated(rowIdx, colIdx, currency, false);
          }
        } else {
          toast.warning('Cannot convert node to a currency');
          onCellLocationUpdated(rowIdx, colIdx, locations, true);
        }
        break;
      }

      // If a node was clicked, and we've got a percentage metric cell selected in the metric table,
      // see if the node had a normalised value/exponent.
      case metricMapping.MET_NUMBER.backend:
      case metricMapping.MET_PERCENTAGE.backend: {
        const normalisedNumber = annotationToNormalisedNumberWithoutExponent(data);
        const locations = createLocationsFromAnnotations(annotations);
        if (isSet(normalisedNumber)) {
          updateCellValueAndLoc(rowIdx, colIdx, normalisedNumber, locations);
        } else {
          toast.warning('Cannot convert node to a number or percentage');
          onCellLocationUpdated(rowIdx, colIdx, locations, true);
        }
        break;
      }

      // If a node was clicked, and we've got a date metric cell selected in the metric table,
      // see if the node had a normalised date value.
      case metricMapping.MET_DATE.backend: {
        const normalisedDate = annotationToNormalisedDate(data);
        const locations = createLocationsFromAnnotations(annotations);
        if (isSet(normalisedDate)) {
          updateCellValueAndLoc(rowIdx, colIdx, normalisedDate, locations);
        } else {
          toast.warning('Cannot convert node to a date');
          onCellLocationUpdated(rowIdx, colIdx, locations, true);
        }
        break;
      }

      // If a node was clicked, and we've got a text metric cell selected in the metric table,
      // use the raw text value.
      case metricMapping.MET_TEXT.backend:
        // If none, use raw text
        if (isSet(data.t)) {
          const rawText = data.t;
          const locations = createLocationsFromAnnotations(annotations);
          updateCellValueAndLoc(rowIdx, colIdx, rawText, locations);
        } else {
          logger.warn('No text value found for node');
          toast.warning('Cannot convert node to a text value');
        }
        break;

      case metricMapping.MET_ENTITY.backend: {
        // Unlike other metric types, only locators need updating for entities.
        const locations = createLocationsFromAnnotations(annotations);
        onCellLocationUpdated(rowIdx, colIdx, locations);
        break;
      }

      // do nothing if unknown type
      default:
        logger.warn('Not processing metric of type: ', metric.metricType);
        break;
    }
  };
  const updateSelectedWithBroadcastedAnnotations = (rowIdx, colIdx, annotations) => {
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    logger.debug(`Processing metric type: ${metric.metricType}. Annotations:`, annotations);

    updateSelectedCellAnnotations(rowIdx, colIdx, metric, annotations);
  };
  // Methods -- data loading
  const getFilterGroupOptions = (colHeaders) => {
    const foundFilterGroups = new Set();
    colHeaders.forEach((c) => {
      if (isSet(c.filterGroup) && c.filterGroup !== '') {
        foundFilterGroups.add(c.filterGroup);
      }
    });
    return Array.from(foundFilterGroups);
  };
  const loadNewTable = (newInitialTable) => {
    logger.debug('loadNewTable');
    if (!newInitialTable.columnHeaders) {
      return;
    }
    columnHeaders.value = newInitialTable.columnHeaders;
    filterOptions.value = getFilterGroupOptions(columnHeaders.value);

    // Transpose table by default for certain situations.
    isTransposed.value = !isRepeating.value || newInitialTable.rows.length === 1 || props.defaultTransposed;

    metrics.value = newInitialTable.metrics;
    rows.value = newInitialTable.rows;
    columnHeaderOptions.value = buildDefaultColumnHeaderOptions(columnHeaders.value);
  };
  const onInitialTableChange = (newInitialTable) => {
    // We must load a new table before generating selection cells,
    // as the size of the selection matrix depends on whether the first column is selectable
    loadNewTable(newInitialTable);
    logger.debug('Loaded new table, generating initial selected cells');
    resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
  };
  // Methods -- pdf communication
  const backendLocationToAnnotation = (backendLocation) => ({
    nid: backendLocation.annotationId,
    page: backendLocation.page,
  });
  const broadcastSelected = (rowIdx, colIdx) => {
    // Broadcast this event to a pdf reader.
    // This supports sending a message to a pdf reader in another tab.
    const metricIndex = rows.value[rowIdx][colIdx];
    const metric = metrics.value[metricIndex];
    if (!metric.datapoints.length) {
      logger.warn('Unable to broadcast; no dps for metric');
      return;
    }

    // Get annotations from datapoint and from node id.
    const datapoint = metric.datapoints[0];
    let annotations = [];

    if (!datapoint.value && datapoint.value !== 0) {
      logger.debug('Datapoint has no value, skipping annotation');
    } else if (
      selectedLanguage.value === 'en'
      && !datapoint?.annotationClear && datapoint.nodeId && datapoint.page) {
      logger.debug('Selected language is english - not broadcasting word nodes - broadcasting datapoint.nodeId ', datapoint);
      annotations.push({ nid: datapoint.nodeId.toString(), page: datapoint.page });
    } else {
      annotations = datapoint.annotations.map(backendLocationToAnnotation);
      if (!annotations.length && !datapoint?.annotationClear && datapoint.nodeId && datapoint.page) {
        annotations.push({ nid: datapoint.nodeId.toString(), page: datapoint.page });
      }
    }

    const message = {
      annotations,
      documentRequestId: documentRequestId.value,
    };
    logger.debug('broadcasting (metric)', message, datapoint);
    const channelMetricSelected = new BroadcastChannel('metric-selected');
    channelMetricSelected.postMessage(message);
    channelMetricSelected.close();
  };
  // Methods -- cell/header selection
  const onCellSelected = (rowIdx, colIdx) => {
    logger.debug(`onCellSelected: [${rowIdx},${colIdx}]`);
    if (colIdx < 0) {
      logger.warn('colIdx < 0');
      return;
    }
    if (selectedCells.value[rowIdx][colIdx] !== true) {
      resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
      selectedCells.value[rowIdx][colIdx] = true;
      store.commit('verifyDocument/SET_CAN_VERIFY_ROW_COLUMN', { canVerifyRow: true, canVerifyColumn: true });
      logger.debug('updating cell selected ( new value:', selectedCells.value[rowIdx][colIdx], ')');
    }
    broadcastSelected(rowIdx, colIdx);
  };
  const onHeaderSelected = (headerIdx) => {
    logger.debug('useMetricTableCore onHeaderSelected', headerIdx);
    resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
    selectedHeaders.value[headerIdx] = true;
    store.commit('verifyDocument/SET_CAN_VERIFY_ROW_COLUMN', { canVerifyRow: isTransposed.value, canVerifyColumn: !isTransposed.value });
  };
  const onRowHeaderSelected = (rowHeaderIdx, colIdx) => {
    logger.debug('useMetricTableCore onRowHeaderSelected', rowHeaderIdx, colIdx);
    resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
    selectedRowHeaders.value[rowHeaderIdx] = true;
    logger.debug('updating row header selected ( new value:', selectedRowHeaders.value[rowHeaderIdx], ')');

    // Keep in mind that a row header is also a cell, so selectedCells also need updating.
    if (selectedCells.value[rowHeaderIdx][colIdx] !== true) {
      selectedCells.value[rowHeaderIdx][colIdx] = true;
    }
    store.commit('verifyDocument/SET_CAN_VERIFY_ROW_COLUMN', { canVerifyRow: true, canVerifyColumn: true });

    broadcastSelected(rowHeaderIdx, colIdx);
  };
  const onNodeSelectedMessage = (event) => {
    const eventDocId = event.data.documentRequestId;
    logger.debug('MetricTable received: ', event.data, ' for: ', eventDocId, 'breadcrumbIdx:',
      breadcrumbIdx.value, '(isActive:', isActive.value, ')');

    if (!isActive.value || String(eventDocId) !== String(documentRequestId.value)) {
      return;
    }

    // Reset metrics if specified.
    if (event.data.resetSelectedMetrics) {
      resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
      return;
    }

    const { annotations } = event.data;
    // Update selected cell values with clicked annotation node data.
    selectedCells.value.forEach((cols, rowIdx) => {
      cols.forEach((selectedValue, colIdx) => {
        if (selectedValue) {
          if (!annotations.length) {
            logger.debug('resetting loc for selected cell');
            onCellLocationUpdated(rowIdx, colIdx, []);
          } else {
            updateSelectedWithBroadcastedAnnotations(rowIdx, colIdx, annotations);
          }
        }
      });
    });
  };
  // Warning: Not currently in use, needs revising.
  const onAlternativeAnswerSelected = (datapointIdx) => {
    const allSelectedCoords = selectedCellCoords.value;
    if (allSelectedCoords.length !== 1) {
      logger.warn('Error getting selected cell. allSelected:', allSelectedCoords);
      return; // No cells selected or more than 1 cell selected
    }
    const [rowIdx, colIdx] = allSelectedCoords[0];
    const metricId = rows.value[rowIdx][colIdx];
    const selectedMetric = metrics.value[metricId];
    const selectedDatapoint = selectedMetric.datapoints[datapointIdx];
    if (selectedMetric.metricType === 'CURRENCY') {
      onCellCurrencyValueUpdated(rowIdx, colIdx, selectedDatapoint.effectors.currency);
    }

    updateStateForValue(rowIdx, colIdx, selectedDatapoint.value);
    onCellLocationUpdated(rowIdx, colIdx, constructLocationsFromDatapoint(selectedDatapoint));
    onCellConfidenceUpdated(rowIdx, colIdx, selectedDatapoint.confidence);
    broadcastSelected(rowIdx, colIdx);
  };
  const onHeaderCurrencyChanged = (headerIdx) => {
    logger.debug('onHeaderCurrencyChanged', headerIdx);
    showColumnOptionCurrencyChangeModal.value = true;
    columnOptionCurrencyChangeColIdx.value = headerIdx;
  };
  const onHeaderAdjustScale = (headerIdx, scale) => {
    logger.debug('onHeaderAdjustScale', headerIdx, 'scale', scale);
    rows.value.forEach((row, rowIdx) => {
      onCellScaleValueUpdated(
        rowIdx, headerIdx, scale,
        rowIdx === 0,
      );
    });
  };
  const onHeaderNumericFormatChanged = (headerIdx) => {
    logger.debug('onHeaderNumericFormatChanged', headerIdx);
  };
  const transposeTable = () => {
    logger.debug('Transposing table');
    isTransposed.value = !isTransposed.value;
  };
  const toggleConfidenceIndicators = () => {
    showConfidenceIndicators.value = !showConfidenceIndicators.value;
    logger.debug('Toggled confidence indicators - ', showConfidenceIndicators.value);
  };
  /**
   * Update user view by first changing the group filter if needed,
   * then selecting and jumping the metric updated.
   */
  const updateUserView = async (rowIdx, colIdx) => {
    if (!showColumnIndices.value[colIdx]) {
      currentFilterGroupIdx.value = getJumpToFilterGroupIndex(columnHeaders.value, colIdx, filterOptions.value);
      await new Promise((resolve) => setTimeout(resolve, 1));
    }
    onCellSelected(rowIdx, colIdx);
    jumpToMetric(rowIdx, colIdx, columnHeaders.value.length, rows.value.length, isTransposed.value, showCalculatedTotals.value);
  };
  const undo = () => {
    if (!changesMadeSinceLastAutoSave.value) {
      logger.debug('Undo stack is empty');
      return;
    }

    // Undo last state change.
    const [lastStateChange, revertedStateChange] = undoLastStateChange(
      rows.value, metrics.value, columnHeaders.value, tableStateChanges.value,
    );

    // Re-call 'undo' if the change is nested.
    if (isNestedChange(lastStateChange)) {
      undo();
      redoChanges.value.push(lastStateChange);
      return;
    }

    // Update redo stack.
    redoChanges.value.push(lastStateChange);

    // Go to changed metric.
    if (lastStateChange.type === stateChangeTypes.ROW && lastStateChange.cud === cudTypes.DELETE) {
      updateUserView(revertedStateChange.rowIdx, 1);
    } else {
      updateUserView(lastStateChange.rowIdx, lastStateChange.colIdx);
    }

    // Set store status to ready if there is nothing left to undo.
    if (!changesMadeSinceLastAutoSave.value) {
      store.commit('verifyDocument/SET_AUTO_SAVE_STATUS', allowedStates.IS_READY);
    }
    store.commit('verifyDocument/SET_CAN_REDO', true);
  };
  const redo = () => {
    if (!hasRedoChanges.value) {
      logger.debug('Redo stack is empty');
      return;
    }

    // Remove the last element from the redo stack.
    const lastStateChange = redoChanges.value.pop();

    // Re-call 'redo' if the change is nested.
    if (isNestedChange(lastStateChange)) {
      redo();
      createTableStateChange(lastStateChange, false);
      return;
    }

    // Add state change back to undo stack.
    createTableStateChange(lastStateChange, false);

    // Go to changed metric.
    if (lastStateChange.type === stateChangeTypes.ROW && lastStateChange.cud === cudTypes.DELETE) {
      resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
    } else {
      updateUserView(lastStateChange.rowIdx, lastStateChange.colIdx);
    }

    // Set redo status to false if there is nothing left to redo.
    if (!hasRedoChanges.value) {
      store.commit('verifyDocument/SET_CAN_REDO', false);
    }
  };
  /**
   * Verifies the entire table by iterating over each cell and checking the verification status of metrics.
   */
  const verifyTable = () => {
    let firstUpdate = true;
    columnHeaders.value.forEach((_, columnIdx) => {
      if (!showColumnIndices.value[columnIdx]) {
        return;
      }
      rows.value.forEach((row, rowIdx) => {
        const metric = metrics.value[row[columnIdx]];
        if (!metric.isVerified) {
          onToggleCellVerified(rowIdx, columnIdx, firstUpdate);
          firstUpdate = false;
        }
      });
    });
  };
  /**
   * Verifies a group of metrics based on selected headers or a selected metric column/row.
   * The verification process depends on the table's transposition state.
   */
  const verifyMetricGroup = (isTransposedReversed) => {
    const canVerifyHeaderGroup = isTransposedReversed ? isTransposed.value : !isTransposed.value;
    let firstUpdate = true;

    // First check if any column headers are selected.
    for (let colIdx = 0; colIdx < selectedHeaders.value.length; colIdx++) {
      const selectedValue = selectedHeaders.value[colIdx];
      if (selectedValue && showColumnIndices.value[colIdx]) {
        if (canVerifyHeaderGroup) {
          // eslint-disable-next-line no-loop-func
          rows.value.forEach((row, rowIdx) => {
            const metric = metrics.value[row[colIdx]];
            if (!metric.isVerified) {
              onToggleCellVerified(rowIdx, colIdx, firstUpdate);
              firstUpdate = false;
            }
          });
        } else {
          logger.warn('Cannot verify group of headers; isTransposedReversed:', isTransposedReversed);
          toast.warning('Cannot verify group of headers');
        }
        return;
      }
    }

    // If no column headers are selected, check what metrics are selected.
    const selectedCoords = selectedCellCoords.value;
    if (selectedCoords.length !== 1) {
      logger.warn('Cannot verify column as no metric is selected; selectedCoords:', selectedCoords);
      toast.warning('Cannot verify column as no metric is selected');
      return;
    }
    const firstSelectedCoord = selectedCoords[0];
    if (canVerifyHeaderGroup) {
      const selectedColumnIdx = firstSelectedCoord[1];
      setColumnVerificationStatus(selectedColumnIdx, true);
    } else {
      const selectedRowIdx = firstSelectedCoord[0];
      setRowVerificationStatus(selectedRowIdx);
    }
  };

  // Lifecycle Hooks
  onMounted(() => {
    if (typeof BroadcastChannel === 'undefined') {
      // We expect BroadcastChannels to be unsupported in our Jest tests
      if (process.env.NODE_ENV !== 'test') {
        logger.error('Broadcast channel not supported', process.env.NODE_ENV);
      }
    } else {
      channelNodeSelected.value = new BroadcastChannel('node-selected');
      channelNodeSelected.value.onmessage = onNodeSelectedMessage;
    }
  });
  onUnmounted(() => {
    if (channelNodeSelected.value !== null) {
      channelNodeSelected.value.close();
    }
  });

  // Watchers
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.SAVE].value, (newVal, oldVal) => {
      logger.debug('watch SAVE', newVal, oldVal);
      if (newVal > oldVal) {
        onSaveTriggered();
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.FULL_SAVE].value, (newVal, oldVal) => {
      logger.debug('watch FULL_SAVE', newVal, oldVal);
      if (newVal > oldVal) {
        onSaveTriggered(true);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.ADD_NEW_ROW_ABOVE].value, async (newVal, oldVal) => {
      logger.debug('watch ADD_NEW_ROW_ABOVE', newVal, oldVal);
      if (isRepeating.value && newVal > oldVal && isActive.value) {
        await addNewRowAt(0);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.ADD_NEW_ROW_BELOW].value, async (newVal, oldVal) => {
      logger.debug('watch ADD_NEW_ROW_BELOW', newVal, oldVal);
      if (isRepeating.value && newVal > oldVal && isActive.value) {
        await addNewRowAt(rows.value.length);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.DELETE_SELECTED_ROW].value, (newVal, oldVal) => {
      logger.debug('watch DELETE_SELECTED_ROW', newVal, oldVal);
      if (isRepeating.value && newVal > oldVal && isActive.value) {
        deleteSelectedRow();
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.UPDATE_TABLE_CURRENCY].value, async (newVal, oldVal) => {
      if (newVal > oldVal && isActive.value) {
        await updateTableCurrency();
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.INCREASE_TABLE_SCALE].value, async (newVal, oldVal) => {
      if (newVal > oldVal && isActive.value) {
        await updateTableScale(3);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.DECREASE_TABLE_SCALE].value, async (newVal, oldVal) => {
      if (newVal > oldVal && isActive.value) {
        await updateTableScale(-3);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.TRANSPOSE_TABLE].value, (newVal, oldVal) => {
      logger.debug('watch TRANSPOSE_TABLE', newVal, oldVal);
      transposeTable();
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.TOGGLE_CONFIDENCE_INDICATORS].value, (newVal, oldVal) => {
      logger.debug('watch TOGGLE_CONFIDENCE_INDICATORS', newVal, oldVal);
      toggleConfidenceIndicators();
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.UNDO].value, (newVal, oldVal) => {
      logger.debug('watch UNDO', newVal, oldVal);
      undo();
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.REDO].value, (newVal, oldVal) => {
      logger.debug('watch REDO', newVal, oldVal);
      redo();
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.BREADCRUMB_SWITCH].value, (newVal, oldVal) => {
      logger.debug('watch BREADCRUMB_SWITCH', newVal, oldVal);
      if (newVal > oldVal) {
        resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
        broadcastResetSelected();
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.VERIFY_TABLE].value, (newVal, oldVal) => {
      logger.debug('watch VERIFY_TABLE', newVal, oldVal);
      if (newVal > oldVal && isActive.value) {
        verifyTable();
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.VERIFY_COLUMN].value, (newVal, oldVal) => {
      logger.debug('watch VERIFY_COLUMN', newVal, oldVal);
      if (newVal > oldVal && isActive.value) {
        verifyMetricGroup(false);
      }
    },
  );
  watch(
    () => verifyMenuActionTriggers[verifyMenuActions.VERIFY_ROW].value, (newVal, oldVal) => {
      logger.debug('watch VERIFY_ROW', newVal, oldVal);
      if (newVal > oldVal && isActive.value) {
        verifyMetricGroup(true);
      }
    },
  );
  watch(
    () => props.initialTable, (newInitialTable) => {
      setTimeout(() => {
        onInitialTableChange(clonedeep(newInitialTable));
      });
    }, { immediate: true },
  );
  watch(
    () => selectedCellAlternativeAnswers.value, (newAlternativeAnswers) => {
      logger.debug('new newAlternativeAnswers', newAlternativeAnswers, '(isActive:', isActive.value, ')');
      if (isActive.value) {
        store.dispatch('verifyDocumentAlternativeAnswers/updateAlternatives', {
          breadcrumbIndex: breadcrumbIdx.value,
          alternativeAnswers: newAlternativeAnswers,
        });
      }
    },
  );
  watch(
    () => store.getters['verifyDocumentAlternativeAnswers/selectedAlternativeAnswerTrigger'], () => {
      if (isActive.value) {
        const dpIndex = store.getters['verifyDocumentAlternativeAnswers/selectedAlternativeAnswerIndex'];
        onAlternativeAnswerSelected(dpIndex);
      }
    },
  );
  watch(
    () => currentFilterGroupIdx.value, (newFilterGroupIdx) => {
      logger.debug('New filter group idx:', newFilterGroupIdx);
      resetSelectedCellsAndHeaders(rows.value, columnHeaders.value);
    },
  );

  return {
    // Data
    addEntityOnboardingName,
    columnHeaders,
    columnHeaderOptions,
    columnOptionCurrencyChangeColIdx,
    currentFilterGroupIdx,
    filterOptions,
    filterShowNotReported,
    isTransposed,
    lastSuccessfulAutoSaveStateChangeIndex,
    metrics,
    newEntityModalRowIdx,
    entityModalMetricFin,
    entityModalMetricValidators,
    rows,
    selectedCells,
    selectedHeaders,
    selectedRowHeaders,
    showEntityModal,
    showConfidenceIndicators,
    showColumnOptionCurrencyChangeModal,
    showUpdateTableCurrencyModal,
    tableStateChanges,
    renamingEntityModalRowValue,
    // Computed
    calculatedTotals,
    changesMadeSinceLastAutoSave,
    columnVerificationFlags,
    hasEntityNameRowHeaders,
    metricTableIsLoading,
    numColumnIndicesHidden,
    numVisibleColumns,
    numVisibleRows,
    selectedCellAlternativeAnswers,
    showCalculatedTotals,
    showColumnIndices,
    showRowIndices,
    verifyDocumentStoreIsReady,
    // Methods
    addNewRowAt,
    autoSave,
    deleteRowAt,
    broadcastSelected,
    generateInitialSelectedCells,
    onSubmitEntity,
    onEntityModalClose,
    onAlternativeAnswerSelected,
    onCellAkasUpdated,
    onCellCurrencyValueUpdated,
    onCellSelected,
    onCellSelectedEntityUpdated,
    onCellValueUpdated,
    onColumnOptionCurrencyChangeClose,
    onColumnOptionCurrencyChangeConfirmed,
    onUpdateTableCurrencyClose,
    onUpdateTableCurrencyConfirmed,
    onHeaderCurrencyChanged,
    onHeaderAdjustScale,
    onHeaderNumericFormatChanged,
    onHeaderSelected,
    onRenameEntityConfirmed,
    onRowHeaderSelected,
    onInitialTableChange,
    onRenameEntityAt,
    onRemoveEntity,
    onSaveTriggered,
    onToggleCellVerified,
    onToggleHeaderVerified,
    sendAutoSaveRequest,
    updateScaleSelectedCells,
    updateSelectedWithBroadcastedNode: updateSelectedWithBroadcastedAnnotations,
    undo,
    redo,
  };
};
