<template>
  <div
    class="vertical-align"
    :class="customClasses"
  >
    <template
      v-for="(breadcrumb, idx) in breadcrumbs"
      :key="breadcrumb.breadcrumb"
    >
      <span
        v-if="idx !== 0"
        class="breadcrumb-separation-icon icon-container-static"
      >
        <span class="pipe-icon">|</span>
      </span>

      <router-link
        class="breadcrumb-text top-nav-item"
        :class="{'breadcrumb-text-selected': idx === currentBreadcrumbIndex}"
        :to="navLink(idx)"
      >
        {{ breadcrumb.navText }}
      </router-link>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    currentBreadcrumbIndex: {
      type: Number,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  methods: {
    navLink(index) {
      return `/verification/${this.documentRequestId}/${index}`;
    },
  },
};
</script>
