<template>
  <div class="verified-tick-container">
    <div
      v-if="showToggle"
      :class="{ 'icon-container': true, 'is-verified': isVerified }"
      @click.stop="onVerifiedClick"
    >
      <check-circle-icon
        size="1.25x"
        stroke="black"
      />
    </div>
  </div>
</template>
<script>
import { CheckCircleIcon } from '@zhuowenli/vue-feather-icons';

export default {
  components: {
    CheckCircleIcon,
  },
  props: {
    showToggle: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleCellVerified'],
  methods: {
    onVerifiedClick() {
      this.$emit('toggleCellVerified');
    },
  },
};
</script>
