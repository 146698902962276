<template>
  <div
    class="filter-toggle"
    :class="{...customClasses, 'filter-toggle-active': modelValue}"
    @click="onFilterToggled"
  >
    {{ currentLabel }}
    <div
      v-show="badgeText !== ''"
      class="badge-text-container"
    >
      <div class="badge-text">
        {{ badgeText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    badgeText: {
      type: String,
      default: '',
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    labelActive: {
      type: String,
      required: true,
    },
    labelInactive: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentLabel() {
      return this.modelValue ? this.labelActive : this.labelInactive;
    },
  },
  methods: {
    onFilterToggled() {
      this.$log.info('onFilterToggled, new value:', !this.modelValue);
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>
